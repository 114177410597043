/*------------------------------------*\
    #PADDING
\*------------------------------------*/

// padding all

.pa8 {
  padding: 80px;
}

.pa7 {
  padding: 64px;
}

.pa6 {
  padding: 48px;
}

.pa5 {
  padding: 32px;
}

.pa4 {
  padding: 24px;
}

.pa3 {
  padding: 16px;
}

.pa2 {
  padding: 8px;
}

.pa1 {
  padding: 4px;
}

.pa0 {
  padding: 0;
}


// padding top

.pt8 {
  padding-top: 80px;
}

.pt7 {
  padding-top: 64px;
}

.pt6 {
  padding-top: 48px;
}

.pt5 {
  padding-top: 32px;
}

.pt4 {
  padding-top: 24px;
}

.pt3 {
  padding-top: 16px;
}

.pt2 {
  padding-top: 8px;
}

.pt1 {
  padding-top: 4px;
}

.pt0 {
  padding-top: 0;
}

// padding left

.pl8 {
  padding-left: 80px;
}

.pl7 {
  padding-left: 64px;
}

.pl6 {
  padding-left: 48px;
}

.pl5 {
  padding-left: 32px;
}

.pl4 {
  padding-left: 24px;
}

.pl3 {
  padding-left: 16px;
}

.pl2 {
  padding-left: 8px;
}

.pl1 {
  padding-left: 4px;
}

.pl0 {
  padding-left: 0;
}

// padding right

.pr8 {
  padding-right: 80px;
}

.pr7 {
  padding-right: 64px;
}

.pr6 {
  padding-right: 48px;
}

.pr5 {
  padding-right: 32px;
}

.pr4 {
  padding-right: 24px;
}

.pr3 {
  padding-right: 16px;
}

.pr2 {
  padding-right: 8px;
}

.pr1 {
  padding-right: 4px;
}

.pr0 {
  padding-right: 0;
}

/*------------------------------------*\
    PADDING
\*------------------------------------*/

// padding bottom

.pb8 {
  padding-bottom: 80px;
}

.pb7 {
  padding-bottom: 64px;
}

.pb6 {
  padding-bottom: 48px;
}

.pb5 {
  padding-bottom: 32px;
}

.pb4 {
  padding-bottom: 24px;
}

.pb3 {
  padding-bottom: 16px;
}

.pb2 {
  padding-bottom: 8px;
}

.pb1 {
  padding-bottom: 4px;
}

.pb0 {
  padding-bottom: 0;
}
