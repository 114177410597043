/*------------------------------------*\
    #INPUT-GROUP
\*------------------------------------*/

/**
 * Component Modifier (based on .Input): Input Groups
 */

.Input-group {
  position: relative;
  display: flex;
  width: 100%;

 }

.Input-group__addon {
  background-color: $color-snow-20;
  border: 1px solid $color-smoke-5;
  border-radius: $radius-default;
  color: $color-smoke-30;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
}

.Input-group__addon:not(:first-child) {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.Input-group__addon:not(:last-child) {
  border-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.Input-group .Input:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.Input-group .Input:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/**
* Component Modifier: Input Group Sizes
*/

.Input-group--l {
	font-size: $font-size-default;
}

.Input-group--m {
  font-size: $font-size-xs;
}

.Input-group--s {
	font-size: $font-size-xxs;
}
