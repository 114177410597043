/*------------------------------------*\
    #TABLE
\*------------------------------------*/

/*
Component: Core Table
*/

table {
	display: table;
	border-collapse: separate;
	border-spacing: 2px;
	border-color: grey;
}

thead {
	display: table-header-group;
	vertical-align: middle;
	border-color: inherit;
}

tbody {
	display: table-row-group;
	vertical-align: middle;
	border-color: inherit;
}

tr {
	display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.Table {
	border: solid 1px #efefef;
	border-collapse: collapse;
	border-spacing: 0;
	display: inline-block;
	text-align: left;
	overflow: auto;
	white-space: nowrap;
	width: 100%;
}

@include breakpoint(md) {
	.Table {
		display: table;
	}
}

.Table.Table-striped tbody tr:nth-of-type(odd) {
	background: #fafcfd;
}

.Table th, .Table td {
	border-bottom: .1rem solid #efefef;
	padding: 0 1rem;
	height: 63px;
	line-height: 63px;
}

.Tabel.label {
    background: #fafcfd;
    border-radius: .2rem;
    color: #666;
    font-size: .95em;
    padding: .1em .3em;
    vertical-align: baseline;
    white-space: nowrap;
}
