/**
 * Breakpoints
 *
 *
$base-font-size = 100%/16px
  *
*/


@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: $bp-xs) { @content; }
  }

  @else if $class == sm {
    @media (min-width: $bp-sm) { @content; }
  }

  @else if $class == md {
    @media (min-width: $bp-md) { @content; }
  }

  @else if $class == ml {
    @media (min-width: $bp-ml) { @content; }
  }

  @else if $class == lg {
    @media (min-width: $bp-lg) { @content; }
  }

  @else if $class == xl {
    @media (min-width: $bp-xl) { @content; }
  }

  @else if $class == bg {
    @media (min-width: $bp-bg) { @content; }
  }

  @else if $class == xbg {
    @media (min-width: $bp-xbg) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, ml, lg, xl, bg, xbg";
  }
}

/**
* Mobile-first styles FTW
*
Example:
*/
aside.primary {
  /* Regular content */
  @include breakpoint(sm) {
    /* responsive content */
  }
}
