/*------------------------------------*\
    #GRID
\*------------------------------------*/

/**
 * Grid
  based on ...
 */

 /*
 Object: Core Grid
 */


  .Grid {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   grid-gap: 32px;
   margin: 0 auto;
   max-width: 1170px;
   width: 90%;
 }

@include breakpoint(sm) {
   .Grid--2 {
     grid-template-columns: 1fr 1fr;
   }
 }

@include breakpoint(md) {
   .Grid--2 {
     grid-template-columns: 1fr 1fr;
   }
 }

@include breakpoint(sm) {
   .Grid--3 {
     grid-template-columns: 1fr 1fr;
   }
 }

@include breakpoint(md) {
   .Grid--3 {
     grid-template-columns: 1fr 1fr 1fr;
   }
 }

 /**
 * Object Modifiers: Demo specific modifer, don not use in production.
 */

.Grid--demo {
  background: rgba(225, 69, 0,.2);
  transition: background-color 0.3s ease;
  border: 1px dotted #ff4500;

  p {
    padding: 16px;
  }

  &:hover {
   background: rgba(225, 69, 0,.6);
 }
}
