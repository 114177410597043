/*------------------------------------*\
    #CONTROL
\*------------------------------------*/

/*
Component: Core input type radio
*/

input[type="radio"],
input[type="checkbox"] {
    cursor: pointer;
    outline-style: none;
    position: relative;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    margin-bottom: -0.25em;
    margin-right: 5px;
    vertical-align: top;
}

input[type="checkbox"]{
    background-color: #fff;
    border: 1px solid #cdd3d9;
    border-radius: 4px;
    color: #484848;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

input[type="radio"] {
    background-color: #fff;
    border: 1px solid #cdd3d9;
    border-radius: 50px;
    color: #484848;
    outline-style: none;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
}


input[type="checkbox"]:checked:after,
input[type="radio"]:checked:after {
  background: #4a8cff;
  border: solid 1px #4a8cff;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  text-align: center;
  top: -1px;
  right: -1px;
  width: 110%;
  height: 110%;

}

input[type="checkbox"]:checked:after {
    border-radius: 4px;
    content: "done";
    font-size: 0.7em;
    line-height: 1.1;
}

input[type="radio"]:checked:after {
    border-radius: 50px;
    content: "lens";
    font-size: 0.34em;
    line-height: 2.3;
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
	  background: #F2F6F9;
    border: solid 1px #cdd3d9;
    pointer-events: none;
}

input[type="radio"]:disabled:checked:after,
input[type="checkbox"]:disabled:checked:after {
    color: #cdd3d9;
    background: #f2f6f9;
    border: solid 1px #cdd3d9;
}
