/*------------------------------------*\
    #BORDER
\*------------------------------------*/

.border-grey {
  border: solid 1px $color-border;
  border-radius: $radius-default;
}

/**
 * Border
 */

.ba {
  border: solid 1px $color-border;
}

.br {
  border-right: solid 1px $color-border;
}

.bl {
  border-left: solid 1px $color-border;
}

.bt {
  border-top: solid 1px $color-border;
}

.bb {
  border-bottom: solid 1px $color-border;
}
