@charset "UTF-8";
/**
 * Breakpoints
 *
$base-font-size = 100%/16px
  *
*/
/**
 * Brand Colors
 */
/**
 * Greyscale
 */
/**
 * UI Colors
 */
/**
 * Typography
 */
@font-face {
  font-family: 'myriad-pro';
  src: url("../images/fonts/MyriadPro-Regular.eot");
  src: url("../images/fonts/MyriadPro-Regular.eot") format("embedded-opentype"), url("../images/fonts/MyriadPro-Regular.woff") format("woff"), url("../images/fonts/MyriadPro-Regular.ttf") format("truetype"), url("../images/fonts/MyriadPro-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * Font families
 */
/**
 * Font sizes new
 */
/**
 * Font weights
 */
/**
 * Line-heights
 */
/**
 * Breakpoints
 *
 *
$base-font-size = 100%/16px
  *
*/
/**
* Mobile-first styles FTW
*
Example:
*/
aside.primary {
  /* Regular content */ }
  @media (min-width: 37.5em) {
    aside.primary {
      /* responsive content */ } }

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.cf:after {
  clear: both; }

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1; }

/*------------------------------------*    #BASE
\*------------------------------------*/
/**
 * Base styles
 */
/**
 * Enable font anti-aliasing in
 * 1. Firefox
 * 2. Chrome
 */
html {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  /* 1 */
  -webkit-font-smoothing: antialiased;
  /* 2 */ }

body {
  color: #1B202F;
  font-family: "myriad-pro", sans-serif;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  min-height: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility; }

/**
 * Ensure all 'a' elements have correct cursor style
 */
a {
  color: #4A8CFF;
  cursor: pointer; }

/**
 * Reset font-weight of header elements
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400; }

/**
 * Reset the margin of these elements.
 */
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
/* stylelint-disable selector-no-qualifying-type */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  /* 2 */ }

button {
  border: 0;
  padding: 0; }

/*------------------------------------*    #BOX SIZING
\*------------------------------------*/
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Add a flattened line height in all browsers (opinionated).
 * 4. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  line-height: 1.5;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 4 */
  -webkit-text-size-adjust: 100%;
  /* 4 */ }

/* Sections (https://www.w3.org/TR/html5/sections.html)
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
   ========================================================================== */
/**
 * 1. Correct font sizing inheritance and scaling in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * 1. Correct the height in Firefox.
 * 2. Add visible overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Add a bordered underline effect in all browsers.
 * 2. Remove text decoration in Firefox 40+.
 */
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */
  text-decoration: none;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct colors in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Correct the font size in all browsers.
 */
small {
  font-size: 83.3333%; }

/**
 * Change the positioning on superscript and subscript elements
 * in all browsers (opinionated).
 * 1. Correct the font size in all browsers.
 */
sub,
sup {
  font-size: 83.3333%;
  /* 1 */
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Links (https://www.w3.org/TR/html5/links.html#links)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline when hovering in all browsers (opinionated).
 */
a:hover {
  outline-width: 0; }

/* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
   ========================================================================== */
/*
 * Remove border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* transform-style:  (https://www.w3.org/TR/html5/forms.html)
   ========================================================================== */
/**
 * 1. Remove the default styling in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  background-color: transparent;
  /* 1 */
  border-style: none;
  /* 1 */
  color: inherit;
  /* 1 */
  font-size: 1em;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Correct the overflow in IE.
 * 1. Correct the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance in Edge, Firefox, and IE.
 * 1. Remove the inheritance in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Correct the focus styles unset by the previous rule.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Correct the border, margin, and padding in all browsers.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Remove the vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * Correct the cursor style on increment and decrement buttons in Chrome.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
 */
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style on placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

/* User interaction (https://www.w3.org/TR/html5/editing.html)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

[hidden][aria-hidden="false"]:focus {
  clip: auto; }

/*------------------------------------*    #SHARED
\*------------------------------------*/
button:focus, textarea {
  outline: none; }

span {
  display: inline-block; }

code {
  color: #4A8CFF; }

/*------------------------------------*    #HEADING
\*------------------------------------*/
h1 {
  font-size: 3rem; }

h2 {
  font-size: 2.25rem; }

h3 {
  font-size: 1.75rem; }

h4 {
  font-size: 1.5rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 16px; }

/*------------------------------------*    #LINK
\*------------------------------------*/
a {
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none; }
  a:active {
    text-decoration: none; }

/*------------------------------------*    #LIST
\*------------------------------------*/
li > ul,
li > ol {
  margin-left: 0;
  margin-bottom: 0; }

/*------------------------------------*    #IMAGE
\*------------------------------------*/
/**
 * Enable basic responsive image support
 */
img {
  max-width: 100%;
  vertical-align: middle; }

/*------------------------------------*    #BLOCKQUOTE
\*------------------------------------*/
blockquote {
  quotes: "“" "”"; }
  blockquote p {
    text-indent: -0.42em; }
    blockquote p:before {
      content: open-quote; }
    blockquote p:after {
      content: no-close-quote; }
    blockquote p:last-of-type {
      margin-bottom: 0; }
      blockquote p:last-of-type:after {
        content: close-quote; }

/*------------------------------------*    #FLEXBOX GRID
\*------------------------------------*/
/**
 * Flexbox grid
  based on https://codepen.io/harrycresswell/pen/MrYvmZ
  and https://www.taniarascia.com/easiest-flex-grid-ever/
 */
/*
 Object: Core Flexbox grid
 */
.Flex-column {
  flex-basis: 100%; }

@media (min-width: 37.5em) {
  .Flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
  .Flex-column {
    flex: 1; } }

/**
 * Non-responsive grids.
 * Add to Flex-row to keep grids even on smaller screens
 * E.g. class="Flex-row Flex--nr"
 */
.Flex-row.Flex--nr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

/**
 * Object Modifiers: Flexbox styles.
 */
/**
 * Column sizes.
 * Add to Flex-column to modify individual cells
 * E.g. class="Flex-column Flex--75"
 */
@media (min-width: 37.5em) {
  .Flex--75 {
    flex: 7.5; }
  .Flex--65 {
    flex: 6.5; }
  .Flex--50 {
    flex: 5; }
  .Flex--30 {
    flex: 3; }
  .Flex--25 {
    flex: 2.5; }
  .Flex--15 {
    flex: 1.5; }
  .Flex-column--1of2 {
    flex: 0 0 50%; }
  .Flex-column--1of4 {
    flex: 0 0 25%; }
  .Flex--1of3 {
    flex: 0 0 30%; }
  .Flex--1of6 {
    flex: 0 0 16.6666%; } }

/* Justify per row*/
.Flex--right {
  justify-content: flex-end; }

.Flex--center {
  justify-content: center; }

/* Alignment per row */
.Flex-row--top {
  align-items: flex-start; }

.Flex-row--bottom {
  align-items: flex-end; }

.Flex-row--center {
  align-items: center; }

/* Alignment per column */
.Flex-column--top {
  align-self: flex-start; }

.Flex-column--bottom {
  align-self: flex-end; }

.Flex-column--center {
  align-self: center; }

/**
 * Add Gutters to columns.
 * Add to Flex-row to give columns gutters
 * E.g. class="Flex-row Flex--gutters"
 */
.Flex--gutters > div {
  margin: 16px; }

/**
 * Object Modifiers: Demo specific modifer, don not use in production.
 */
.Flex--demo {
  background: rgba(51, 153, 204, 0.2);
  transition: background-color 0.3s ease;
  border: 1px solid #33cccc;
  padding: 16px;
  margin: 8px; }
  .Flex--demo:hover {
    background: rgba(51, 153, 204, 0.6); }

/*------------------------------------*    #GRID
\*------------------------------------*/
/**
 * Grid
  based on ...
 */
/*
 Object: Core Grid
 */
.Grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 32px;
  margin: 0 auto;
  max-width: 1170px;
  width: 90%; }

@media (min-width: 37.5em) {
  .Grid--2 {
    grid-template-columns: 1fr 1fr; } }

@media (min-width: 48em) {
  .Grid--2 {
    grid-template-columns: 1fr 1fr; } }

@media (min-width: 37.5em) {
  .Grid--3 {
    grid-template-columns: 1fr 1fr; } }

@media (min-width: 48em) {
  .Grid--3 {
    grid-template-columns: 1fr 1fr 1fr; } }

/**
 * Object Modifiers: Demo specific modifer, don not use in production.
 */
.Grid--demo {
  background: rgba(225, 69, 0, 0.2);
  transition: background-color 0.3s ease;
  border: 1px dotted #ff4500; }
  .Grid--demo p {
    padding: 16px; }
  .Grid--demo:hover {
    background: rgba(225, 69, 0, 0.6); }

/*------------------------------------*    #TABLE
\*------------------------------------*/
/*
Component: Core Table
*/
table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: grey; }

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit; }

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit; }

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit; }

.Table {
  border: solid 1px #efefef;
  border-collapse: collapse;
  border-spacing: 0;
  display: inline-block;
  text-align: left;
  overflow: auto;
  white-space: nowrap;
  width: 100%; }

@media (min-width: 48em) {
  .Table {
    display: table; } }

.Table.Table-striped tbody tr:nth-of-type(odd) {
  background: #fafcfd; }

.Table th, .Table td {
  border-bottom: .1rem solid #efefef;
  padding: 0 1rem;
  height: 63px;
  line-height: 63px; }

.Tabel.label {
  background: #fafcfd;
  border-radius: .2rem;
  color: #666;
  font-size: .95em;
  padding: .1em .3em;
  vertical-align: baseline;
  white-space: nowrap; }

*, ::after, ::before {
  position: relative;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit; }

.c-header {
  font-size: 14px;
  border: 0; }

.c-header {
  background: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  border: 1px solid #dfe9f2;
  border-right: 0;
  border-left: 0;
  /*-webkit-box-shadow:0 2px 10px 0 rgba(27,32,47,0.5);
  box-shadow:0 2px 10px 0 rgba(27,32,47,0.5); */
  z-index: 9999; }

.c-header__title {
  text-decoration: none; }

.c-nav a {
  color: #37474F; }

.c-nav--primary {
  min-height: 74px; }

.c-nav--secondary {
  border-top: solid 1px #DFE9F2;
  min-height: 50px; }

.c-nav__logo {
  background-image: url(../images/ain-logo-dark-with-blue-icon-e880b785.png);
  background-repeat: no-repeat;
  display: block;
  width: 147px;
  height: 49px;
  margin: 12px; }

.c-nav__icon {
  background-image: url(../images/icon-ain-dark-blue-a53d3b2f.png);
  background-repeat: no-repeat;
  display: block;
  width: 47px;
  height: 47px;
  margin: 13px; }

.c-nav__network {
  font-size: 12px;
  padding: 1.5rem; }

.c-nav__items {
  margin: 0 0 0 1rem; }

.c-nav__item {
  display: inline-block;
  font-size: 14px; }

.c-nav__link-accent {
  display: inline-block;
  margin: 0rem 1rem;
  text-decoration: none;
  padding: 1rem 0rem; }

.c-nav__link-accent:hover:after,
.c-nav__link-accent:active {
  display: block; }

.c-nav__link-accent:after {
  display: none;
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: #4A8CFF; }

.c-nav__link {
  color: #253142;
  display: inline-block;
  padding: 1rem;
  text-decoration: none; }

.c-nav__link:hover {
  color: #253142; }

.c-nav__link.active {
  color: #253142; }

.c-nav__button {
  border: solid 2px #CDD3D9;
  background-color: #fff;
  padding: 0.5rem 2rem;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  vertical-align: middle;
  margin-right: 20px;
  font-size: 14px; }

.c-nav__left {
  border-right: solid 1px #DFE9F2; }

.c-nav__middle {
  padding-top: 14px; }

.c-nav__right {
  border-left: solid 1px #DFE9F2; }

.c-nav__search {
  outline: none;
  border: 0; }

.user-avatar {
  background: -webkit-gradient(linear, left top, left bottom, from(#FAD961), to(#F76B1C));
  background: -webkit-linear-gradient(top, #FAD961 0%, #F76B1C 100%);
  background: linear-gradient(180deg, #FAD961 0%, #F76B1C 100%);
  -webkit-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  height: 40px;
  width: 40px; }

.nudges {
  padding: 12px; }

.nudges-count {
  margin-right: 20px;
  font-size: .8em; }

.c-icon-network {
  background-image: url("../images/maps-sprite-3e5f57ba.png"); }

.c-icon-united-kingdom {
  background-position: -0px -3984px;
  width: 32px;
  height: 24px; }

.ain__dropdown-menu {
  padding: 0rem 0;
  list-style: none; }

.dropdown-menu__link {
  padding: 1.5rem;
  text-decoration: none;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  min-width: 260px;
  right: 0;
  border: solid 1px #DFE9F2; }

.dropdown-content a {
  color: #253142;
  padding: 15px 30px;
  text-decoration: none;
  display: block;
  text-align: left; }

.dropdown-content a:hover {
  background-color: #F6F9FB; }

.c-nav__item:hover .dropdown-content {
  display: block; }

.dropdown-menu__item:hover .dropdown-content {
  display: block; }

.notification-indicator-user-avatar .dropdown-content {
  display: block; }

.notification-indicator-user-avatar {
  border-left: solid 1px #DFE9F2;
  display: inline-block;
  vertical-align: middle;
  padding: 0.82rem; }

.notification-indicator {
  position: absolute;
  top: 6px;
  right: 6px; }

.notification-indicator:after {
  background: #3AD283;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  vertical-align: middle;
  height: 10px;
  width: 10px;
  display: inline-block;
  content: "";
  z-index: 9999; }

.ac-container {
  max-width: 400px;
  background: #F6F9FB; }

.ac-container label {
  height: 64px !important;
  line-height: 21px !important;
  font-weight: bold;
  padding: 20px 20px;
  position: relative;
  z-index: 20;
  display: block;
  cursor: pointer;
  background: #fff;
  border-top: solid 1px #D8E2EC;
  border-bottom: solid 1px #D8E2EC; }

a.top-level {
  padding: 0;
  color: #253142; }

a.top-level:hover {
  background: #fff; }

.ac-container {
  width: 100%;
  text-align: left; }

.ac-container label:hover {
  background: #fff; }

.ac-container input:checked + label,
.ac-container input:checked + label:hover {
  background: #fff;
  height: 30px;
  line-height: 21px; }

label.no-border-top {
  border-top: none; }

label.no-border-bottom {
  border-bottom: none; }

.i-chevron-down {
  content: '';
  position: absolute;
  width: 8px;
  height: 4px;
  right: 18px;
  top: 30px;
  background: transparent url(../images/arrow_down-e4febe09.png) no-repeat center center; }

.ac-container input {
  display: none; }

.ac-container article {
  background: rgba(255, 255, 255, 0.5);
  margin-top: -1px;
  overflow: hidden;
  height: 0;
  position: relative;
  z-index: 10; }

.ac-container input:checked ~ article.ac-small {
  height: 145px; }

.ac-container input:checked ~ article.ac-medium {
  height: 195px; }

.ac-container input:checked ~ article.ac-large {
  height: 245px; }

.ac-container input:checked ~ article.ac-extra-large {
  height: 295px; }

.c-nav-banner {
  color: #FFFFFF;
  padding: 6px;
  display: block;
  z-index: 99999;
  text-align: center;
  font-size: 12px;
  font-weight: bold; }

.c-nav-banner__link {
  text-decoration: none;
  color: #FFFFFF; }

.c-nav-banner__success {
  background-color: #3AD283; }

.c-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.c-icon {
  font-size: 30px;
  margin-left: 10px;
  vertical-align: middle; }

.c-hero {
  background: #DFE9F2;
  min-height: 240px;
  padding: 150px 30px;
  text-align: center; }

.c-hero__title {
  text-decoration: none; }

.u-pull-left {
  float: left !important; }

.u-pull-right {
  float: right !important; }

.cf {
  *zoom: 1;
  content: "";
  clear: both; }

.cf:before, .cf:after {
  content: " ";
  display: table; }

.cf:after {
  clear: both; }

.u-link--natural {
  color: #4A8CFF !important;
  text-decoration: underline !important; }

.nudges {
  padding: 12px;
  line-height: 29px; }

.c-nav__link-accent {
  text-decoration: none !important;
  font-size: 14px; }

.c-nav__link {
  color: #253142;
  display: inline-block;
  padding: 1rem;
  padding: 14px;
  text-decoration: none !important;
  font-size: 14px; }

.user-avatar img {
  height: 100%;
  overflow: hidden;
  border-radius: 60%; }

.ac-container label {
  margin: 0; }

.c-nav-banner__success a {
  color: #fff; }

.ac-container input:checked ~ article.ac-small {
  height: auto; }

.active .c-nav__link-accent:after, .active .c-nav__link-accent:active {
  display: block; }

header.c-header {
  overflow: inherit; }

.c-nav a.Button--primary {
  color: #fff; }

.ain__dropdown-menu .c-nav__button {
  border: 0; }

.dropdown-menu {
  margin: 0; }

.dropdown-menu__link {
  text-decoration: none;
  display: inline-block;
  line-height: 23px;
  height: 74px; }

.c-nav__link {
  line-height: 44px; }

.c-header input.c-nav__search {
  outline: none;
  border: 0;
  height: auto;
  width: auto;
  font-size: 14px;
  display: inline-block;
  box-shadow: inset 0 0 0 0 #e0e6ed; }

.ac-container label {
  margin: 0;
  font-size: 14px;
  font-weight: 500; }

.ac-container input:checked ~ article.ac-small {
  height: 145px; }

.ac-container ul {
  margin: 0; }

.ac-container input:checked ~ article.ac-small {
  height: auto; }

.dropdown-menu {
  display: none; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .c-icon-network {
    background-image: url("../images/maps-sprite@2x-1e316076.png");
    background-size: 32px 4200px;
    display: inline-block;
    vertical-align: middle; } }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .i-chevron-down {
    background: transparent url(../images/arrow_down@2x-b0fd63ce.png);
    background-size: 8px 4px;
    background-repeat: no-repeat;
    display: block;
    width: 8px;
    height: 4px;
    margin-right: 10px; } }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .c-nav__logo {
    background-image: url(../images/ain-logo-dark-with-blue-icon@2x-82707f60.png);
    background-size: 147px 49px; }
  .c-nav__icon {
    background-image: url(../images/icon-ain-dark-blue@2x-a8d3a6bb.png);
    background-size: 47px 47px; } }

@media screen and (max-width: 959px) {
  .dropdown-menu {
    display: block; }
  .c-nav__network {
    display: none; }
  .c-nav .c-nav__item {
    display: none; }
  .ain__dropdown-menu {
    display: none; }
  .c-nav--secondary {
    display: none; }
  .notification-indicator-user-avatar {
    border-left: 0; } }

@media screen and (max-width: 1079px) {
  .c-nav__link-text {
    display: none; } }

/* 
Filter Elements ---------------------------------- 
*/
.c-icon-network {
  background-image: url("../images/maps-sprite-3e5f57ba.png"); }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .c-icon-network {
    background-image: url("../images/maps-sprite@2x-1e316076.png");
    background-size: 32px 4200px;
    display: inline-block;
    vertical-align: middle; } }

.c-icon-united-kingdom {
  background-position: -0px -3984px;
  width: 32px;
  height: 24px; }

.Nav {
  font-size: 14px;
  list-style-type: none;
  width: 100%; }

.Nav--primary {
  height: 72px;
  border-bottom: solid 1px #e8e8e8; }

.Nav--secondary {
  border-bottom: solid 1px #e8e8e8;
  height: 48px; }

@media screen and (max-width: 959px) {
  .Nav--secondary {
    display: none; } }

.Nav-logo {
  background-repeat: no-repeat;
  display: block;
  margin: 13px; }

.Nav-logo--ain {
  background-image: url(../images/ain-logo-dark-with-blue-icon.png);
  background-repeat: no-repeat;
  display: block;
  width: 145px;
  height: 46px;
  margin: 12px; }

.Nav-logo--bricktribe {
  background-size: 196px 47px;
  width: 198px;
  height: 46px;
  background-image: url("https://www.bricktribe.com/assets/img/logo/logo-bricktribe-dark-wordmark-color-icon-bg-beta@2x.png"); }

.Nav-logo--seedtribe {
  background-size: 246px 47px;
  width: 248px;
  height: 46px;
  background-image: url("https://www.seedtribe.com//assets/img/logo/logo-seedtribe-by-ain-dark-color-icona@2x.png"); }

.Nav-logo--ain-icon {
  background-image: url(../images/icon-ain-dark-blue.png);
  width: 47px;
  height: 47px; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .Nav-logo--ain {
    background-image: url(../images/icon-ain-dark-blue@2x.png);
    background-size: 47px 47px; }
  .Nav-logo--ain-icon {
    background-image: url(../images/ain-logo-dark-with-blue-icon@2x.png);
    background-size: 147px 49px; } }

.Nav__network {
  font-size: 12px;
  padding: 1.5rem; }

@media screen and (max-width: 959px) {
  .Nav-network {
    display: none; } }

.Nav-items {
  margin: 0;
  padding: 0; }

@media screen and (max-width: 959px) {
  .Nav .Nav-item {
    display: none; } }

.Nav-item {
  display: inline-block; }

.Nav-link-accent {
  color: #37474F;
  display: inline-block;
  position: relative;
  margin-right: 16px;
  line-height: 48px; }

.Nav-link-accent:hover:after, .Nav-link-accent:active {
  display: block; }

.Nav-link-accent:after {
  display: none;
  content: "";
  position: absolute;
  bottom: 0px;
  height: 3px;
  left: 0;
  right: 0;
  background-color: #4A8CFF; }

.Nav-link {
  color: #253142;
  display: inline-block;
  line-height: 72px;
  padding-right: 32px;
  text-decoration: none; }

.Nav-link:hover {
  color: #253142; }

.Nav-link.active {
  color: #253142; }

.Nav-button {
  border: solid 2px #CDD3D9;
  background-color: #fff;
  padding: 0.5rem 2rem;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  vertical-align: middle;
  margin-right: 20px; }

@media screen and (max-width: 1079px) {
  .Nav-text {
    display: none; } }

.Nav-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 30px;
  margin-left: 10px;
  vertical-align: middle; }

.Nav-left {
  float: left; }

.Nav-right {
  float: right; }

.Nav-search {
  outline: none;
  border: 0;
  padding: 1.5rem 0.5rem; }

.Nav-avatar {
  background: -webkit-gradient(linear, left top, left bottom, from(#FAD961), to(#F76B1C));
  background: -webkit-linear-gradient(top, #FAD961 0%, #F76B1C 100%);
  background: linear-gradient(180deg, #FAD961 0%, #F76B1C 100%);
  -webkit-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  height: 40px;
  width: 40px;
  vertical-align: middle;
  margin-right: 16px; }

.Nav-itemnudges {
  padding: 12px; }

.nudges-count {
  margin-right: 20px;
  font-size: 14px; }

.Nav-left, .Nav-right {
  border: 0;
  padding: 0; }

.Nav-middle {
  height: 74px; }

input.Nav-search {
  height: 74px !important; }

.Checkbox-parent {
  border-top: solid 1px #e8e8e8;
  margin-top: 16px;
  padding-top: 16px; }

.Checkbox-parent:first-of-type {
  border: 0;
  margin-top: 0;
  padding-top: 0; }

.Checkbox-child {
  margin-bottom: 8px;
  margin-left: 16px; }

.Checkbox-child li {
  list-style: none;
  margin-bottom: 8px; }

.Checkbox-child li:first-of-type {
  margin-top: 16px; }

.Accordion:after {
  content: '\002B';
  color: #4f5766;
  font-weight: bold;
  float: right;
  margin-left: 5px; }

.Accordion--active:after {
  content: "\2212";
  color: #4f5766; }

.Accordion-panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out; }

.Accordion-panel ul {
  padding: 0; }

.Accordion-panel:last-of-type {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 16px; }

.Header {
  -webkit-box-shadow: none;
  box-shadow: none; }

.Filter {
  padding: 16px 0 0 0;
  border-bottom: solid 1px #e8e8e8; }

.Filter-item {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 16px; }

.FilterPopup {
  display: none;
  position: absolute;
  top: 48px !important;
  left: 0px;
  z-index: 10;
  background: #ffffff;
  -webkit-box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.4);
  padding: 32px;
  overflow-x: hidden;
  white-space: normal;
  overflow-y: scroll;
  max-height: -webkit-calc(100vh - 360px);
  max-height: -webkit-calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  min-width: 360px; }

.FilterRange {
  max-width: 300px; }

.FilterRange-val {
  color: #4A8CFF; }

.FilterSearch, .FilterButton {
  margin-right: 16px; }

@media screen and (max-width: 499px) {
  .FilterSearch, .FilterButton {
    margin-right: 0; } }

@media screen and (max-width: 499px) {
  .FilterPopup--quicklinks, .FilterPopup--price, .FilterPopup--country, .FilterPopup--location, .FilterPopup--industry, .FilterPopup--stage, .FilterPopup--language, .FilterPopup--taxrelief, .FilterPopup--fundingtype, .FilterPopup--funding, .FilterPopup--property {
    left: 0px;
    margin: 16px;
    position: fixed; } }

.Container {
  margin: 0 auto;
  width: 90%;
  max-width: 1170px; }

/*! nouislider - 11.0.3 - 2018-01-21 14:04:07 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect, .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute; }

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-horizontal {
  height: 6px; }

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 32px;
  left: -17px;
  top: -11px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 20px;
  height: 32px;
  left: -6px;
  top: -17px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
  outline: 0; }

.noUi-target {
  background: #DFE9F2;
  -webkit-border-radius: 4px;
  border-radius: 4px; }

.noUi-connects {
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.noUi-connect {
  background: #4A8CFF; }

.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  -webkit-box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  -webkit-box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

.noUi-handle:before, .noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed; }

.noUi-pips, .noUi-pips * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  -ms-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%, 0);
  transform: translate(0, -50%, 0);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

/*------------------------------------*    #CARD
\*------------------------------------*/
/*
Component: Card
*/
/**
 * Component: Card Block
 */
.Card {
  background: #FFFFFF;
  /* Shadow container :normal: */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  min-height: 100%;
  position: relative;
  top: 0;
  transition: all .1s ease-in; }
  .Card:hover {
    top: -2px;
    /* Shadow container :elevated: */
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.04), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 7px 8px 0 rgba(0, 0, 0, 0.05); }

/**
 * Component Modifier: Card Landscape
 */
.Card--landscape {
  display: grid;
  grid-template-columns: 1fr; }

@media (min-width: 37.5em) {
  .Card--landscape {
    grid-template-columns: 1fr 1fr; } }

/**
 * Component: Card-thumb
 */
.Card-thumb {
  background: #1B202F;
  background-size: cover;
  background-position: center center;
  min-height: 184px;
  position: relative;
  height: 100%; }

.Card-progress {
  position: absolute;
  bottom: 24px;
  width: 85%; }

/**
 * Component Modifier: Card-thumb portrait & Landscape
 */
.Card-thumb--portrait {
  border-radius: 4px 4px 0 0; }

.Card-thumb--landscape {
  border-radius: 4px 4px 0 0; }

@media (min-width: 37.5em) {
  .Card-thumb--landscape {
    border-radius: 4px 0 0 0; } }

.Card--radius {
  border-radius: 4px; }

.Card-label {
  position: absolute;
  right: 24px;
  top: 24px; }
  .Card-label span {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); }

.Card-location {
  color: #4F5766;
  font-size: 1rem;
  position: relative; }
  .Card-location i {
    color: #4A8CFF;
    position: absolute;
    top: 2px; }

.Card-description {
  color: #4F5766;
  font-size: 1rem;
  min-height: 72px; }

.Card-text-links a {
  display: block; }

.Card-grid {
  display: grid;
  grid-gap: 0 16px; }

.Card-grid--2 {
  grid-template-columns: 1fr 1fr; }

.Card-grid--3 {
  grid-template-columns: 1fr 1fr 1fr; }

.Card-grid--4 {
  grid-template-columns: 1fr 1fr; }

.Card-grid--5 {
  grid-template-columns: 1fr 1fr; }

.Card-grid--6 {
  grid-template-columns: 1fr; }

@media (min-width: 48em) {
  .Card-grid--4 {
    grid-template-columns: 1fr 1fr 1fr 1fr; }
  .Card-grid--5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
  .Card-grid--6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; } }

.icon-graphic-booster-bundle, .icon-graphic-booster-expert-review {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: -148px -240px;
  width: 74px;
  height: 74px; }

.icon-graphic-booster-expert-review {
  background-position: -74px -240px; }

.icon-graphic-booster-featured-exposure, .icon-graphic-booster-immediate-release {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: 0 -240px;
  width: 74px;
  height: 74px; }

.icon-graphic-booster-immediate-release {
  background-position: -222px -240px; }

.icon-graphic-booster-nudges, .icon-graphic-booster-privacy-feature, .icon-graphic-booster-renew {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: -314px 0;
  width: 74px;
  height: 74px; }

.icon-graphic-booster-privacy-feature, .icon-graphic-booster-renew {
  background-position: -314px -74px; }

.icon-graphic-booster-renew {
  background-position: -240px -74px; }

.icon-graphic-booster-resend, .icon-graphic-booster-upgrade, .icon-graphic-document-doc {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: -240px -148px;
  width: 74px;
  height: 74px; }

.icon-graphic-booster-upgrade, .icon-graphic-document-doc {
  background-position: -240px 0; }

.icon-graphic-document-doc {
  background-position: -314px -148px;
  width: 40px;
  height: 50px; }

.icon-graphic-document-pdf, .icon-graphic-document-sheet, .icon-graphic-newsfeed-bullseye {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: -314px -198px;
  width: 40px;
  height: 50px; }

.icon-graphic-document-sheet, .icon-graphic-newsfeed-bullseye {
  background-position: -314px -248px; }

.icon-graphic-newsfeed-bullseye {
  background-position: 0 0;
  width: 120px;
  height: 120px; }

.icon-graphic-newsfeed-cake, .icon-graphic-newsfeed-cup, .icon-graphic-newsfeed-thumbs-up {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: -120px 0;
  width: 120px;
  height: 120px; }

.icon-graphic-newsfeed-cup, .icon-graphic-newsfeed-thumbs-up {
  background-position: 0 -120px; }

.icon-graphic-newsfeed-thumbs-up {
  background-position: -120px -120px; }

.icon-graphic-social-companies-house, .icon-graphic-social-duedil, .icon-graphic-social-facebook {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: 0 -314px;
  width: 163px;
  height: 32px; }

.icon-graphic-social-duedil, .icon-graphic-social-facebook {
  background-position: -163px -314px;
  width: 99px; }

.icon-graphic-social-facebook {
  background-position: -354px -148px;
  width: 32px; }

.icon-graphic-social-instagram, .icon-graphic-social-linkedin, .icon-graphic-social-twitter {
  background-image: url(/assets/toolkit/images/sprite.png);
  background-position: -354px -198px;
  width: 32px;
  height: 32px; }

.icon-graphic-social-linkedin, .icon-graphic-social-twitter {
  background-position: -354px -248px; }

.icon-graphic-social-twitter {
  background-position: -262px -314px; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .icon-graphic-booster-bundle, .icon-graphic-booster-expert-review, .icon-graphic-booster-featured-exposure, .icon-graphic-booster-immediate-release, .icon-graphic-booster-nudges, .icon-graphic-booster-privacy-feature, .icon-graphic-booster-renew, .icon-graphic-booster-resend, .icon-graphic-booster-upgrade, .icon-graphic-document-doc, .icon-graphic-document-pdf, .icon-graphic-document-sheet, .icon-graphic-newsfeed-bullseye, .icon-graphic-newsfeed-cake, .icon-graphic-newsfeed-cup, .icon-graphic-newsfeed-thumbs-up, .icon-graphic-social-companies-house, .icon-graphic-social-duedil, .icon-graphic-social-facebook, .icon-graphic-social-instagram, .icon-graphic-social-linkedin, .icon-graphic-social-twitter {
    background-image: url(/assets/toolkit/images/sprite@2x.png);
    background-size: 388px 346px; } }

.TabWrap {
  padding: 0 1.5rem; }

.TabNav {
  position: relative;
  border-bottom: 2px solid #F6F9FB;
  white-space: nowrap; }
  .TabNav::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 1.5rem;
    background-color: #ffffff;
    bottom: -2px;
    left: -1.5rem; }
  .TabNav::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 1.5rem;
    background-color: #ffffff;
    bottom: -2px;
    right: -1.5rem; }
  .TabNav-list {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -2px;
    padding: 0 1.5rem;
    overflow-x: scroll; }
  .TabNav-item {
    border-bottom: 2px solid transparent;
    display: inline-block;
    padding-bottom: 1rem;
    vertical-align: top; }
    .TabNav-item:not(:last-child) {
      margin-right: 1rem; }
    .TabNav-item:hover {
      border-bottom: 2px solid transparent;
      border-color: #4A8CFF;
      cursor: pointer; }
    .TabNav-item[data-state="open"] {
      border-bottom: 2px solid transparent;
      border-color: #4A8CFF;
      color: #4A8CFF; }

/*------------------------------------*    #ALERT
\*------------------------------------*/
/*
Component: Core Alert
*/
.Alert {
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 1.5rem;
  width: 100%; }

/*
Component Modifier: Avatar Shapes
*/
.Alert--info {
  color: #4A8CFF;
  background: #edf4ff; }

.Alert--warning {
  color: #FFC82C;
  background: #fff8e4; }

.Alert--danger {
  color: #FF4949;
  background: #ffe2e2; }

.Alert--success {
  color: #3AD283;
  background: #e8faf1; }

/**
 * Component Modifier: Ghost Theme
 */
.Alert--ghost {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.15); }

/**
 * Component Modifier: AIN Assistant
 */
.Alert--assistant {
  color: #4F5766;
  background: #FFFFFF;
  border: solid 1px #DFE9F2; }

/*------------------------------------*    #ICON
\*------------------------------------*/
/*
Component: Core Icon
*/
/**
 * Component Modifier: Brand icons
 */
.Icon--seedtribe {
  background-image: url("/assets/toolkit/images/ic_seedtribe.svg");
  background-size: 20px 20px; }

/**
 * Component Modifier: Icon Size
 */
.Icon--xs {
  line-height: 28px;
  height: 24px;
  width: 24px; }
  .Icon--xs i {
    position: relative;
    top: 0px; }

/*------------------------------------*    #BUTTON
\*------------------------------------*/
/*
Component: Core button
*/
.Button {
  text-align: center;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  border-radius: 4px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  line-height: 1em; }

/**
 * Component Core: Group Button Style
 */
.Button-group {
  font-size: 0; }

.Button-group .Button--s, .Button-group .Button--m {
  border-radius: 0; }

.Button-group > .Button--primary:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.Button-group > .Button--primary:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

/**
 * Component Core: Link Button Style
 */
.Button-link {
  background: transparent;
  border-color: transparent;
  color: #4A8CFF; }

/**
 * Component Modifier: Primary Button Style
 */
.Button--primary {
  background: #4A8CFF;
  border: none;
  color: #FFFFFF; }

/**
 * Component Modifier: Secondary Button Style
 */
.Button--secondary {
  background: #F2F6F9;
  border: 1px solid #e8e8e8;
  color: #4F5766; }

/**
 * Component Modifier: Tertiary Button Style
 */
.Button--tertiary {
  background: #FFFFFF;
  border: 1px solid #e8e8e8;
  color: #4F5766; }

/**
 * Component Modifier: UI Button Style
 */
.Button--positive {
  background: #13CE66;
  color: #FFFFFF; }

.Button--negative {
  background: #FF4949;
  color: #FFFFFF; }

/**
 * Component Modifier: Ghost Button Style
 */
.Button--ghost {
  border: 1px solid #FFFFFF;
  color: #FFFFFF; }

/**
 * Component Modifier: Round Button Style
 */
.Button--round {
  border-radius: 39px !important; }

/**
 * Component Modifier: Theme Button Style
 */
.Button--theme-blue {
  background: #FFFFFF;
  border: solid 1px #FFFFFF;
  color: #403FFF;
  font-weight: 600; }

/**
 * Component Modifier: FAB Button Style
 */
.Button--floating {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  height: 48px;
  width: 48px; }

.Button--floating i {
  font-size: 1.4rem !important;
  color: #FFFFFF; }

/**
 * Component State: Primary Button Style
 */
.Button--primary:hover,
.Button--primary:focus,
.Button--primary:active,
.Button--primary.is-pressed {
  background: #3B7CEE;
  color: #FFFFFF; }

/**
 * Component State: Secondary Button Style
 */
.Button--secondary:hover,
.Button--secondary:focus,
.Button--secondary:active,
.Button--secondary.is-pressed {
  background: #EEF3F7;
  border: 1px solid #CDD3D9;
  color: #253142; }

/**
 * Component State: Tertiary Button Style
 */
.Button--tertiary:hover,
.Button--tertiary:focus,
.Button--tertiary:active,
.Button--tertiary.is-pressed {
  background: #EEF3F7;
  border: 1px solid #CDD3D9;
  color: #253142; }

/**
 * Component State: Ghost Button Style
 */
.Button--ghost:hover,
.Button--ghost:focus,
.Button--ghost:active,
.Button--ghost.is-pressed {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #FFFFFF;
  color: #FFFFFF; }

/**
 * Component State: UI Button Styles
 */
.Button--positive:hover,
.Button--positive:focus,
.Button--positive:active,
.Button--positive.is-pressed {
  background: #17BF62; }

.Button--negative:hover,
.Button--negative:focus,
.Button--negative:active,
.Button--negative.is-pressed {
  background: #F04444; }

/**
 * Component State: Theme Button Style
 */
.Button--theme-blue:hover,
.Button--theme-blue:focus,
.Button--theme-blue:active,
.Button--theme-blue.is-pressed {
  background: #403FFF;
  border: solid 1px #FFFFFF;
  color: #FFFFFF; }

/**
 * Component State: Disabled Button
 */
.Button.is-disabled {
  background-color: #F2F6F9;
  color: #D8E2EC;
  pointer-events: none;
  cursor: default; }

/**
 * Component Modifier: Button Sizes
 */
.Button--l {
  font-size: 16px;
  line-height: 48px;
  height: 48px;
  padding: 0 24px; }

.Button--m {
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  padding: 0 16px; }

.Button--s {
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  padding: 0 16px; }

/**
 * Component Modifier: Icon Sizes
 */
.Icon--l {
  line-height: 48px;
  height: 48px;
  height: 48px;
  width: 48px; }
  .Icon--l i {
    position: relative;
    top: 4px; }

.Icon--m {
  line-height: 40px;
  height: 40px;
  width: 40px; }
  .Icon--m i {
    position: relative;
    top: 2px; }

.Icon--s {
  line-height: 32px;
  height: 32px;
  width: 32px; }
  .Icon--s i {
    position: relative;
    top: 3px; }

/**
 * Component State: Icon Button
 */
.Icon:focus,
.Icon:active,
.Icon.is-pressed {
  background: rgba(74, 140, 255, 0.1);
  border: 1px solid #4A8CFF; }

/* Rules for colour of the icon when pressed. */
.Icon:focus i,
.Icon:active i,
.Icon.is-pressed i {
  color: #4A8CFF; }

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

/*------------------------------------*    #AVATAR
\*------------------------------------*/
/*
Component: Core Avatar
*/
.Avatar {
  background: #556BCC;
  display: inline-block;
  position: relative;
  border: 1px solid #fff; }

/*
Component Modifier: Avatar Shapes
*/
.Avatar--square {
  border-radius: 4px; }

.Avatar--round {
  border-radius: 50%; }

/*
Component Modifier: Avatar Types
*/
.Avatar--initials {
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }

.Avatar--img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: 1; }

/**
 * Component Modifier: Avatar Sizes
 */
.Avatar--xl {
  font-size: 1.75rem;
  width: 120px;
  height: 120px;
  line-height: 120px; }

.Avatar--l {
  font-size: 1.5rem;
  width: 96px;
  height: 96px;
  line-height: 96px; }

.Avatar--m {
  font-size: 1.25rem;
  width: 80px;
  height: 80px;
  line-height: 80px; }

.Avatar--s {
  font-size: 16px;
  width: 64px;
  height: 64px;
  line-height: 64px; }

.Avatar--xs {
  font-size: 0.75rem;
  width: 48px;
  height: 48px;
  line-height: 48px; }

/*------------------------------------*    #INPUT
\*------------------------------------*/
/*
Component: Core Form Input
*/
.Input, input[type="text"],
input[type="password"] {
  appearance: none;
  border-width: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e8e8e8;
  letter-spacing: 0px;
  line-height: 16px;
  outline-style: none;
  overflow: auto;
  padding: 0 15px;
  transition-duration: 80ms;
  transition-property: all;
  vertical-align: middle;
  width: 100%; }

input[type="password"] {
  -webkit-text-security: disc; }

/**
 * Component Modifier: Default Theme
 */
.Input--default::-webkit-input-placeholder {
  color: #B4C3D3; }

.Input--default::-moz-placeholder {
  /* Firefox 19+ */
  color: #B4C3D3; }

.Input--default:-ms-input-placeholder {
  color: #B4C3D3; }

input[type="text"].Input--default:hover,
input[type="password"].Input--default:hover,
input[type="search"].Input--default:hover {
  box-shadow: inset 0 0 0 1px #c0ccda; }

input[type="text"].Input--default:focus,
input[type="password"].Input--default:focus,
input[type="search"].Input--default:focus {
  box-shadow: inset 0 0 0 1px #4a8cff; }

/**
 * Component Modifier: Input Sizes
 */
.Input--l {
  font-size: 0.875rem;
  height: 48px;
  line-height: 48px; }

.Input--m {
  font-size: 0.75rem;
  height: 40px;
  line-height: 40px; }

.Input--s {
  font-size: 0.75rem;
  height: 32px;
  line-height: 32px; }

/**
 * Component Modifier: Ghost Theme
 */
/**
  * Component Modifier: Input hints
  */
.Input.has-danger {
  border: solid 1px #FF4949; }

.Input.has-success {
  border: solid 1px #3AD283; }

.Input-hint--danger {
  color: #FF4949; }

.Input-hint--success {
  color: #3AD283; }

/*------------------------------------*    #INPUT-GROUP
\*------------------------------------*/
/**
 * Component Modifier (based on .Input): Input Groups
 */
.Input-group {
  position: relative;
  display: flex;
  width: 100%; }

.Input-group__addon {
  background-color: #F2F6F9;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  color: #636F7B;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap; }

.Input-group__addon:not(:first-child) {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.Input-group__addon:not(:last-child) {
  border-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.Input-group .Input:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.Input-group .Input:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

/**
* Component Modifier: Input Group Sizes
*/
.Input-group--l {
  font-size: 16px; }

.Input-group--m {
  font-size: 0.875rem; }

.Input-group--s {
  font-size: 0.75rem; }

/*------------------------------------*    #SEARCH
\*------------------------------------*/
/*
Component: Core Form Input
*/
.Search {
  position: relative; }

/**
* Component Modifier: Search Icon Sizes
*/
.Search__icon--l {
  position: absolute;
  top: 0;
  z-index: 1;
  line-height: 48px;
  padding-left: 16px; }
  .Search__icon--l i {
    vertical-align: middle; }

.Search__icon--m {
  position: absolute;
  top: 0;
  line-height: 40px;
  padding-left: 16px;
  z-index: 1; }
  .Search__icon--m i {
    vertical-align: middle; }

.Search__input {
  width: 100%;
  border-radius: 4px;
  padding: 12px 45px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e8e8e8;
  outline-style: none;
  vertical-align: middle;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0;
  -webkit-transition-duration: 80ms;
  transition-duration: 80ms; }

/*------------------------------------*    #INPUT FILE
\*------------------------------------*/
/*
Component: Core file input (no need to use Input before hand)
*/
input[type="file"] {
  display: none; }

[data-file]:after {
  content: attr(data-file);
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 0.875rem;
  margin: 0.625rem;
  color: #4F5766; }

/*------------------------------------*    #INPUT RANGE
\*------------------------------------*/
/*
Component: Core range input
*/
input[type=range] {
  cursor: pointer;
  /*removes default webkit styles*/
  -webkit-appearance: none;
  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;
  /*required for proper track sizing in FF*/
  width: 300px; }

input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #DFE9F2;
  border: none;
  border-radius: 3px; }

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4A8CFF;
  margin-top: -4px; }

input[type=range]:focus {
  outline: none; }

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #CDD3D9; }

input[type=range]::-moz-range-track {
  width: 300px;
  height: 5px;
  background: #DFE9F2;
  border: none;
  border-radius: 3px; }

input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4A8CFF; }

/*hide the outline behind the border*/
input[type=range]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px; }

input[type=range]::-ms-track {
  width: 300px;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: #DFE9F2;
  border-radius: 10px; }

input[type=range]::-ms-fill-upper {
  background: #DFE9F2;
  border-radius: 10px; }

input[type=range]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4A8CFF; }

input[type=range]:focus::-ms-fill-lower {
  background: #CDD3D9; }

input[type=range]:focus::-ms-fill-upper {
  background: #CDD3D9; }

/*------------------------------------*    #TEXTAREA
\*------------------------------------*/
/*
Component: Core Form Text Area
*/
textarea {
  width: 100%;
  border-radius: 4px;
  padding: 10px 15px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e8e8e8;
  outline-style: none;
  vertical-align: middle;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0;
  -webkit-transition-duration: 80ms;
  transition-duration: 80ms;
  letter-spacing: 0px;
  line-height: 26px; }

/**
 * Component Modifier: Default Theme
 */
.Textarea--default::-webkit-input-placeholder {
  color: #B4C3D3; }

.Textarea--default::-moz-placeholder {
  /* Firefox 19+ */
  color: #B4C3D3; }

.Textarea--default:-ms-input-placeholder {
  color: #B4C3D3; }

.Textarea--default:hover {
  box-shadow: inset 0 0 0 1px #c0ccda; }

.Textarea--default:focus {
  box-shadow: inset 0 0 0 1px #4a8cff; }

/**
  * Component Modifier: Ghost Theme
  */
/**
  * Component Modifier: Input Sizes
  */
.Textarea--l {
  font-size: 0.875rem;
  min-height: 120px; }

.Textarea--m {
  font-size: 0.75rem;
  min-height: 80px; }

.Textarea--s {
  font-size: 0.75rem;
  min-height: 60px; }

/*------------------------------------*    #SELECT
\*------------------------------------*/
/*
Component: Core Form Input
*/
select {
  text-transform: none;
  margin: 0;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-style: none; }

.Select {
  width: 100%;
  border-radius: 4px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e8e8e8;
  outline-style: none;
  vertical-align: middle;
  border-width: 0;
  -webkit-transition-duration: 80ms;
  transition-duration: 80ms; }

.Select:after {
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  -webkit-transition: none;
  transition: none;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 100; }

/**
 * Component Modifier: Default Theme
 */
.Select--default:hover {
  box-shadow: inset 0 0 0 1px #c0ccda; }

/**
  * Component Modifier: Ghost Theme
  */
/**
  * Component Modifier: Input Sizes
  */
.Select--l select {
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  padding: 0 50px 0 20px;
  position: relative;
  z-index: 200; }

.Select--m select {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 50px 0 20px;
  position: relative;
  z-index: 200; }

.Select--s select {
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 50px 0 20px;
  position: relative;
  z-index: 200; }

.Select--l:after {
  width: 24px;
  height: 24px;
  margin-top: 14px;
  margin-right: 14px;
  background-size: 24px 24px;
  background-image: url("/assets/toolkit/images/icon-dropdown--m.svg"); }

.Select--m:after {
  width: 24px;
  height: 24px;
  margin-top: 8px;
  margin-right: 10px;
  background-size: 24px 24px;
  background-image: url("/assets/toolkit/images/icon-dropdown--s.svg"); }

.Select--s:after {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-right: 6px;
  background-size: 24px 24px;
  background-image: url("/assets/toolkit/images/icon-dropdown--s.svg"); }

/*------------------------------------*    #SELECT-GROUP
\*------------------------------------*/
/**
 * Component Modifier (based on .Select): Select Groups
 */
.Select-group {
  position: relative;
  display: flex;
  width: 100%; }

.Select-group__addon {
  background-color: #F2F6F9;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  color: #636F7B;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.Select-group__addon:not(:first-child) {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.Select-group__addon:not(:last-child) {
  border-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.Select-group .Select:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.Select-group .Select:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

/**
* Component Modifier: Input Group Sizes
*/
.Select-group--l {
  font-size: 16px; }

.Select-group--m {
  font-size: 0.875rem; }

.Select-group--s {
  font-size: 0.75rem; }

/*------------------------------------*    #CONTROL
\*------------------------------------*/
/*
Component: Core input type radio
*/
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
  outline-style: none;
  position: relative;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  margin-bottom: -0.25em;
  margin-right: 5px;
  vertical-align: top; }

input[type="checkbox"] {
  background-color: #fff;
  border: 1px solid #cdd3d9;
  border-radius: 4px;
  color: #484848;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px; }

input[type="radio"] {
  background-color: #fff;
  border: 1px solid #cdd3d9;
  border-radius: 50px;
  color: #484848;
  outline-style: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px; }

input[type="checkbox"]:checked:after,
input[type="radio"]:checked:after {
  background: #4a8cff;
  border: solid 1px #4a8cff;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  text-align: center;
  top: -1px;
  right: -1px;
  width: 110%;
  height: 110%; }

input[type="checkbox"]:checked:after {
  border-radius: 4px;
  content: "done";
  font-size: 0.7em;
  line-height: 1.1; }

input[type="radio"]:checked:after {
  border-radius: 50px;
  content: "lens";
  font-size: 0.34em;
  line-height: 2.3; }

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  background: #F2F6F9;
  border: solid 1px #cdd3d9;
  pointer-events: none; }

input[type="radio"]:disabled:checked:after,
input[type="checkbox"]:disabled:checked:after {
  color: #cdd3d9;
  background: #f2f6f9;
  border: solid 1px #cdd3d9; }

/*------------------------------------*    #LABEL
\*------------------------------------*/
/*
Component: Core Label
*/
.Label {
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 0.75rem;
  height: 24px;
  line-height: 24px;
  padding: 0 16px; }

.Label-icon {
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 0.75rem;
  height: 24px;
  line-height: 24px;
  position: relative; }

/*
Component Modifier: Label Shapes
*/
.Label--round {
  border-radius: 39px; }

/*
Component Modifier: Label Colors
*/
.Label--default {
  color: #4F5766;
  background: #DFE9F2; }

.Label--info, .Label--brand {
  background: #4A8CFF; }

.Label--warning {
  background: #FFC82C; }

.Label--danger {
  background: #FF4949; }

.Label--success {
  background: #3AD283; }

.Label--white {
  color: #4F5766;
  background: #FFFFFF;
  border: solid 1px #CDD3D9; }

.Label--dark {
  background: #1B202F; }

.Label--seedtribe {
  background: #6d12ff;
  background: -moz-linear-gradient(left, #6d12ff 0%, #6d12ff 38%, #005dff 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #6d12ff), color-stop(38%, #6d12ff), color-stop(100%, #005dff));
  background: -webkit-linear-gradient(left, #6d12ff 0%, #6d12ff 38%, #005dff 100%);
  background: -o-linear-gradient(left, #6d12ff 0%, #6d12ff 38%, #005dff 100%);
  background: -ms-linear-gradient(left, #6d12ff 0%, #6d12ff 38%, #005dff 100%);
  background: linear-gradient(to right, #6d12ff 0%, #6d12ff 38%, #005dff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6d12ff', endColorstr='#005dff', GradientType=1 ); }

/**
 * Component Modifier: Ghost Theme
 */
.Alert--ghost {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.15); }

/**
 * Component Modifier: Label icon position. Required by all labels with an icon.
 */
.Label-icon--right {
  padding: 0 32px 0 16px; }
  .Label-icon--right i {
    position: absolute;
    right: 8px;
    top: 2px; }

.Label-icon--left {
  padding: 0 16px 0 32px; }
  .Label-icon--left i {
    position: absolute;
    left: 8px;
    top: 2px; }

.Label-icon--brand {
  height: 32px;
  line-height: 24px;
  padding: 4px 16px 4px 32px; }
  .Label-icon--brand i {
    position: absolute;
    left: 8px;
    top: 6px; }

/*------------------------------------*    #MARKER
\*------------------------------------*/
/*
Component: Core Marker
*/
.Marker {
  border-radius: 39px;
  color: #FFFFFF;
  height: 8px;
  margin-right: 8px;
  width: 8px; }

/*
Component Modifier: Marker Colors
*/
.Marker--default {
  background: #DFE9F2; }

.Marker--info, .Marker--brand {
  background: #4A8CFF; }

.Marker--warning {
  background: #FFC82C; }

.Marker--danger {
  background: #FF4949; }

.Marker--success {
  background: #3AD283; }

.Marker--white {
  background: #FFFFFF;
  border: solid 1px #CDD3D9; }

.Marker--dark {
  background: #1B202F; }

/**
 * Component Modifier: Ghost Theme
 */
.Marker--ghost {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.15); }

/*------------------------------------*    #LOADING
\*------------------------------------*/
/*
Component: Core Label
*/
.Loading {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  position: relative;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 3px solid rgba(74, 140, 255, 0.15);
  border-right: 3px solid rgba(74, 140, 255, 0.15);
  border-bottom: 3px solid rgba(74, 140, 255, 0.15);
  border-top: 3px solid rgba(74, 140, 255, 0.9);
  border-radius: 100%; }

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg); }
  to {
    -o-transform: rotate(359deg); } }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

/*------------------------------------*    #FORM
\*------------------------------------*/
/*
Component: Form
*/
.Form-label {
  display: block;
  line-height: 1.6rem;
  margin-bottom: .3rem; }

/*------------------------------------*    #TAG
\*------------------------------------*/
/*
Component: Core Tag
*/
.Tag {
  display: inline-block;
  background: #FFFFFF;
  border: solid 1px #CDD3D9;
  border-radius: 4px;
  color: #4F5766;
  margin: .1rem .2rem .1rem 0;
  position: relative; }

.Tag:hover {
  color: #4A8CFF;
  border: solid 1px #4A8CFF; }

/**
 * Component Modifier: Tag Sizes
 */
.Tag--l {
  font-size: 16px;
  height: 48px;
  line-height: 44px;
  padding: 0 40px 0 16px; }
  .Tag--l button {
    position: absolute;
    right: 0;
    top: 13px;
    padding-right: 8px; }

.Tag--m {
  font-size: 0.875rem;
  height: 40px;
  line-height: 36px;
  padding: 0 32px 0 16px; }
  .Tag--m button {
    position: absolute;
    right: 0;
    top: 11px;
    padding-right: 8px; }

.Tag--s {
  font-size: 0.875rem;
  height: 32px;
  line-height: 30px;
  padding: 0 32px 0 16px; }
  .Tag--s button {
    position: absolute;
    right: 0;
    top: 8px;
    padding-right: 8px; }

.Tag--round {
  border-radius: 39px; }

/*------------------------------------*    #TOOLTIP
\*------------------------------------*/
/**
 * Component: Core Tooltip
 */
.Tooltip {
  position: relative; }

.Tooltip-text {
  border-radius: 4px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  font-size: 13px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  padding: 0 8px;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  transition: opacity 0.5s;
  visibility: hidden;
  white-space: nowrap;
  z-index: 1000;
  /* absurdity 2 */ }

.Tooltip:hover .Tooltip-text {
  visibility: visible;
  opacity: 1; }

/**
  * Component Modifier: Tooltip position
  */
.Tooltip-text--top {
  left: 50%;
  transform: translate(-50%, -0.5em);
  bottom: calc(100% + 4px); }

.Tooltip-text--bottom {
  left: 50%;
  transform: translate(-50%, 0.5em);
  top: calc(100% + 4px); }

.Tooltip-text--left {
  top: 50%;
  right: calc(100% + 4px);
  transform: translate(-0.5em, -50%); }

.Tooltip-text--right {
  top: 50%;
  left: calc(100% + 4px);
  transform: translate(0.5em, -50%); }

/**
  * Component Modifier: Tooltip theme
  */
.Tooltip-text--default {
  background: #1B202F; }

.Tooltip-text--success {
  background: #3AD283; }

.Tooltip-text--danger {
  background: #FF4949; }

.Tooltip-text--info {
  background: #4A8CFF; }

/*------------------------------------*    #PROGRESS
\*------------------------------------*/
/*
Component: Core ProgressBar
*/
.ProgressBar {
  background: #DFE9F2;
  width: 100%;
  border-radius: 4px; }

/**
 * Component Modifier: Core ProgressBar size modifier
 */
.ProgressBar--l {
  height: 32px; }

.ProgressBar--s {
  height: 6px; }

/**
 * Component Element: ProgressBar successs
 */
.ProgressBar-success {
  background: #3AD283;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

/**
 * Component Modifier: ProgressBar--success size
 */
.ProgressBar-success--l {
  height: 32px; }

.ProgressBar-success--s {
  height: 6px; }

/**
 * Component Element: ProgressBar text
 */
.ProgressBar-text {
  color: #FFFFFF;
  font-size: 0.875rem;
  padding: 0 8px;
  line-height: 32px; }

/*------------------------------------*    #BORDER
\*------------------------------------*/
.border-grey {
  border: solid 1px #e8e8e8;
  border-radius: 4px; }

/**
 * Border
 */
.ba {
  border: solid 1px #e8e8e8; }

.br {
  border-right: solid 1px #e8e8e8; }

.bl {
  border-left: solid 1px #e8e8e8; }

.bt {
  border-top: solid 1px #e8e8e8; }

.bb {
  border-bottom: solid 1px #e8e8e8; }

/*------------------------------------*    #LINK
\*------------------------------------*/
.u-link--bare {
  text-decoration: none; }
  .u-link--bare:hover {
    color: inherit;
    text-decoration: underline; }

.u-link-natural {
  text-decoration: underline !important;
  color: inherit !important; }

.u-link-obvious {
  text-decoration: underline !important; }

/* NEW LINK HELPERS */
.Link {
  opacity: 1;
  text-decoration: none;
  transition: color .15s ease-in; }

.Link:active, .Link:focus, .Link:hover, .Link:visited {
  opacity: .8;
  text-decoration: none;
  transition: color .15s ease-in; }

.Link--underline {
  text-decoration: underline !important; }

.Link--underline-hover:focus,
.Link--underline-hover:hover {
  text-decoration: underline; }

/*------------------------------------*    #MARGIN
\*------------------------------------*/
.ma8 {
  margin: 80px; }

.ma7 {
  margin: 64px; }

.ma6 {
  margin: 48px; }

.ma5 {
  margin: 32px; }

.ma4 {
  margin: 24px; }

.ma3 {
  margin: 16px; }

.ma2 {
  margin: 8px; }

.ma1 {
  margin: 4px; }

.ma0 {
  margin: 0; }

.mt8 {
  margin-top: 80px; }

.mt7 {
  margin-top: 64px; }

.mt6 {
  margin-top: 48px; }

.mt5 {
  margin-top: 32px; }

.mt4 {
  margin-top: 24px; }

.mt3 {
  margin-top: 16px; }

.mt2 {
  margin-top: 8px; }

.mt1 {
  margin-top: 4px; }

.mt0 {
  margin-top: 0; }

.ml8 {
  margin-left: 80px; }

.ml7 {
  margin-left: 64px; }

.ml6 {
  margin-left: 48px; }

.ml5 {
  margin-left: 32px; }

.ml4 {
  margin-left: 24px; }

.ml3 {
  margin-left: 16px; }

.ml2 {
  margin-left: 8px; }

.ml1 {
  margin-left: 4px; }

.ml0 {
  margin-left: 0; }

.mr8 {
  margin-right: 80px; }

.mr7 {
  margin-right: 64px; }

.mr6 {
  margin-right: 48px; }

.mr5 {
  margin-right: 32px; }

.mr4 {
  margin-right: 24px; }

.mr3 {
  margin-right: 16px; }

.mr2 {
  margin-right: 8px; }

.mr1 {
  margin-right: 4px; }

.mr0 {
  margin-right: 0; }

.mb8 {
  margin-bottom: 80px; }

.mb7 {
  margin-bottom: 64px; }

.mb6 {
  margin-bottom: 48px; }

.mb5 {
  margin-bottom: 32px; }

.mb4 {
  margin-bottom: 24px; }

.mb3 {
  margin-bottom: 16px; }

.mb2 {
  margin-bottom: 8px; }

.mb1 {
  margin-bottom: 4px; }

.mb0 {
  margin-bottom: 0; }

/*------------------------------------*    #PADDING
\*------------------------------------*/
.pa8 {
  padding: 80px; }

.pa7 {
  padding: 64px; }

.pa6 {
  padding: 48px; }

.pa5 {
  padding: 32px; }

.pa4 {
  padding: 24px; }

.pa3 {
  padding: 16px; }

.pa2 {
  padding: 8px; }

.pa1 {
  padding: 4px; }

.pa0 {
  padding: 0; }

.pt8 {
  padding-top: 80px; }

.pt7 {
  padding-top: 64px; }

.pt6 {
  padding-top: 48px; }

.pt5 {
  padding-top: 32px; }

.pt4 {
  padding-top: 24px; }

.pt3 {
  padding-top: 16px; }

.pt2 {
  padding-top: 8px; }

.pt1 {
  padding-top: 4px; }

.pt0 {
  padding-top: 0; }

.pl8 {
  padding-left: 80px; }

.pl7 {
  padding-left: 64px; }

.pl6 {
  padding-left: 48px; }

.pl5 {
  padding-left: 32px; }

.pl4 {
  padding-left: 24px; }

.pl3 {
  padding-left: 16px; }

.pl2 {
  padding-left: 8px; }

.pl1 {
  padding-left: 4px; }

.pl0 {
  padding-left: 0; }

.pr8 {
  padding-right: 80px; }

.pr7 {
  padding-right: 64px; }

.pr6 {
  padding-right: 48px; }

.pr5 {
  padding-right: 32px; }

.pr4 {
  padding-right: 24px; }

.pr3 {
  padding-right: 16px; }

.pr2 {
  padding-right: 8px; }

.pr1 {
  padding-right: 4px; }

.pr0 {
  padding-right: 0; }

/*------------------------------------*    PADDING
\*------------------------------------*/
.pb8 {
  padding-bottom: 80px; }

.pb7 {
  padding-bottom: 64px; }

.pb6 {
  padding-bottom: 48px; }

.pb5 {
  padding-bottom: 32px; }

.pb4 {
  padding-bottom: 24px; }

.pb3 {
  padding-bottom: 16px; }

.pb2 {
  padding-bottom: 8px; }

.pb1 {
  padding-bottom: 4px; }

.pb0 {
  padding-bottom: 0; }

/*------------------------------------*    #WIDTH
\*------------------------------------*/
.width-100 {
  width: 100%; }

/*------------------------------------*    #COLOR
\*------------------------------------*/
/*
  Brand colors
*/
.color-ain {
  color: #4A8CFF; }

.color-blue-bright {
  color: #403FFF; }

.color-brand {
  color: #4A8CFF; }

/*
  Greyscale colors
*/
.color-white {
  color: #FFFFFF; }

.color-dark {
  color: #1B202F; }

.color-grey {
  color: #F9FAFC; }

.color-grey-80 {
  color: #253142; }

.color-grey-60 {
  color: #4F5766; }

.color-grey-50 {
  color: #9ea3a6; }

.color-grey-40 {
  color: #979CA6; }

.color-grey-30 {
  color: #CDD3D9; }

/*
  UI colors
*/
.color-green {
  color: #3AD283; }

.color-red {
  color: #FF4949; }

.color-blue {
  color: #4A8CFF; }

.color-yellow {
  color: #FFC82C; }

/*------------------------------------*    #BACKGROUND COLOR
\*------------------------------------*/
/*
  Brand colors
*/
.bg-ain {
  background: #4A8CFF; }

.bg-blue-bright, .bg-ain-bright {
  background: #403FFF; }

.bg-blue-opactiy {
  background: #97bdff; }

/*
  Greyscale colors
*/
.bg-white {
  background: #FFFFFF; }

.bg-dark {
  background: #1B202F; }

.bg-grey {
  background: #F9FAFC; }

/*
  UI colors
*/
.bg-green {
  background: #3AD283; }

.bg-red {
  background: #FF4949; }

.bg-blue {
  background: #4A8CFF; }

.bg-yellow {
  background: #FFC82C; }

/*------------------------------------*    #FONT SIZE
\*------------------------------------*/
.f9 {
  font-size: 3rem; }

.f8 {
  font-size: 2.25rem; }

.f7 {
  font-size: 1.75rem; }

.f6 {
  font-size: 1.5rem; }

.f5 {
  font-size: 1.25rem; }

.f4 {
  font-size: 1.125rem; }

.f3 {
  font-size: 1rem; }

.f2 {
  font-size: 0.875rem; }

.f1 {
  font-size: 0.75rem; }

.f0 {
  font-size: 0.625rem; }

.b {
  font-weight: 600; }

/*------------------------------------*    #Z-INDEX
\*------------------------------------*/
/*

  Z-INDEX

  Base
    z = z-index

  Modifiers
    -0 = literal value 0
    -1 = literal value 1
    -2 = literal value 2
    -3 = literal value 3
    -4 = literal value 4
    -5 = literal value 5
    -999 = literal value 999
    -9999 = literal value 9999

    -max = largest accepted z-index value as integer

    -inherit = string value inherit
    -initial = string value initial
    -unset = string value unset

  MDN: https://developer.mozilla.org/en/docs/Web/CSS/z-index
  Spec: http://www.w3.org/TR/CSS2/zindex.html
  Articles:
    https://philipwalton.com/articles/what-no-one-told-you-about-z-index/

  Tips on extending:
  There might be a time worth using negative z-index values.
  Or if you are using tachyons with another project, you might need to
  adjust these values to suit your needs.

*/
.z-0 {
  z-index: 0; }

.z-1 {
  z-index: 1; }

.z-2 {
  z-index: 2; }

.z-3 {
  z-index: 3; }

.z-4 {
  z-index: 4; }

.z-5 {
  z-index: 5; }

.z-999 {
  z-index: 999; }

.z-9999 {
  z-index: 9999; }

.z-max {
  z-index: 2147483647; }

.z-inherit {
  z-index: inherit; }

.z-initial {
  z-index: initial; }

.z-unset {
  z-index: unset; }

/*------------------------------------*    #TEXT ALIGN
\*------------------------------------*/
/**
 * Text Align helpers
  based on http://tachyons.io/docs/typography/text-align/

Base
  t = text-align

Modifiers
  l = left
  r = right
  c = center
  j = justify

Media Query Extensions:
  -ns = not-small
  -m  = medium
  -l  = large

*/
.tl {
  text-align: left; }

.tr {
  text-align: right; }

.tc {
  text-align: center; }

.tj {
  text-align: justify; }

@media (min-width: 37.5em) {
  .tl-ns {
    text-align: left; }
  .tr-ns {
    text-align: right; }
  .tc-ns {
    text-align: center; }
  .tj-ns {
    text-align: justify; } }

@media (min-width: 48em) {
  .tl-m {
    text-align: left; }
  .tr-m {
    text-align: right; }
  .tc-m {
    text-align: center; }
  .tj-m {
    text-align: justify; } }

@media (min-width: 75em) {
  .tl-l {
    text-align: left; }
  .tr-l {
    text-align: right; }
  .tc-l {
    text-align: center; }
  .tj-l {
    text-align: justify; } }

.lh-title {
  line-height: 1.6em; }

.lh-body {
  line-height: 1.5rem; }
