/*------------------------------------*\
    #PROGRESS
\*------------------------------------*/

/*
Component: Core ProgressBar
*/

.ProgressBar {
  background: $color-snow-30;
  width: 100%;
  border-radius: $radius-default;
}

/**
 * Component Modifier: Core ProgressBar size modifier
 */

.ProgressBar--l {
  height: 32px;
}

.ProgressBar--s {
  height: 6px;
}

/**
 * Component Element: ProgressBar successs
 */

.ProgressBar-success {
  background: $color-positive;
  border-top-left-radius: $radius-default;
  border-bottom-left-radius: $radius-default;
}

/**
 * Component Modifier: ProgressBar--success size
 */

.ProgressBar-success--l {
  height: 32px;
}

.ProgressBar-success--s {
  height: 6px;
}

/**
 * Component Element: ProgressBar text
 */

.ProgressBar-text {
  color: $color-white;
  font-size: $font-size-xs;
  padding: 0 8px;
  line-height: 32px;
}
