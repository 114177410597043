/*------------------------------------*\
    #BUTTON
\*------------------------------------*/

/*
Component: Core button
*/

.Button {
  text-align: center;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 4px;
  box-sizing: border-box;
  outline: 0;
  display:inline-block;
  line-height: 1em
}

/**
 * Component Core: Group Button Style
 */

.Button-group {
  font-size: 0; // fixes whitespace issue between buttons
}
.Button-group .Button--s, .Button-group .Button--m {border-radius: 0;}
.Button-group > .Button--primary:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.Button-group > .Button--primary:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/**
 * Component Core: Link Button Style
 */

.Button-link {
  background: transparent;
  border-color: transparent;
  color: $color-ain;
}

/**
 * Component Modifier: Primary Button Style
 */


.Button--primary {
  background: $color-ain;
  border: none;
  color: $color-white;
}

/**
 * Component Modifier: Secondary Button Style
 */

.Button--secondary {
  background: $color-snow-20;
  border: 1px solid $color-smoke-5;
  color: $color-smoke-40;
}

/**
 * Component Modifier: Tertiary Button Style
 */

.Button--tertiary {
  background: $color-white;
  border: 1px solid $color-smoke-5;
  color: $color-smoke-40;
}


/**
 * Component Modifier: UI Button Style
 */

.Button--positive {
  background: #13CE66;
  color: $color-white;
}

.Button--negative {
  background: #FF4949;
  color: $color-white;
}

/**
 * Component Modifier: Ghost Button Style
 */

.Button--ghost {
  border: 1px solid $color-white;
  color: $color-white;
}

/**
 * Component Modifier: Round Button Style
 */

.Button--round {
  border-radius: 39px !important;
}


/**
 * Component Modifier: Theme Button Style
 */

.Button--theme-blue {
  background: $color-white;
  border: solid 1px $color-white;
  color: $color-ain-bright;
  font-weight: $font-weight-medium;
}


/**
 * Component Modifier: FAB Button Style
 */

.Button--floating {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.Button--floating i {
  font-size: 1.4rem !important;
  color: $color-white;
}

/**
 * Component State: Primary Button Style
 */

.Button--primary:hover,
.Button--primary:focus,
.Button--primary:active,
.Button--primary.is-pressed {
  background: #3B7CEE;
  color: $color-white;
}

/**
 * Component State: Secondary Button Style
 */
.Button--secondary:hover,
.Button--secondary:focus,
.Button--secondary:active,
.Button--secondary.is-pressed {
  background: #EEF3F7;
  border: 1px solid $color-smoke-10;
  color: #253142;
}

/**
 * Component State: Tertiary Button Style
 */
.Button--tertiary:hover,
.Button--tertiary:focus,
.Button--tertiary:active,
.Button--tertiary.is-pressed {
  background: #EEF3F7;
  border: 1px solid $color-smoke-10;
  color: #253142;
}

/**
 * Component State: Ghost Button Style
 */

.Button--ghost:hover,
.Button--ghost:focus,
.Button--ghost:active,
.Button--ghost.is-pressed {
  background: rgba(255,255,255,0.10);
  border: 1px solid $color-white;
  color: $color-white;
}


/**
 * Component State: UI Button Styles
 */

.Button--positive:hover,
.Button--positive:focus,
.Button--positive:active,
.Button--positive.is-pressed {
  background: #17BF62;

}

.Button--negative:hover,
.Button--negative:focus,
.Button--negative:active,
.Button--negative.is-pressed {
  background: #F04444;

}

/**
 * Component State: Theme Button Style
 */

.Button--theme-blue:hover,
.Button--theme-blue:focus,
.Button--theme-blue:active,
.Button--theme-blue.is-pressed {
  background: $color-ain-bright;
  border: solid 1px $color-white;
  color: $color-white;

}


/**
 * Component State: Disabled Button
 */

.Button.is-disabled {
  background-color: #F2F6F9;
  color: #D8E2EC;
  pointer-events: none;
  cursor: default;
}

/**
 * Component Modifier: Button Sizes
 */

.Button--l {
  font-size: 16px;
  line-height: 48px;
  height: 48px;
  padding: 0 24px;
}

.Button--m {
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  padding: 0 16px;
}

.Button--s {
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  padding: 0 16px;
}


/**
 * Component Modifier: Icon Sizes
 */

 .Icon--l {
   line-height: 48px;
   height: 48px;
   height: 48px;
   width: 48px;

   i {
     position: relative;
     top: 4px;
   }
 }

 .Icon--m {
   line-height: 40px;
   height: 40px;
   width: 40px;

   i {
     position: relative;
     top: 2px;
   }
 }

.Icon--s {
  line-height: 32px;
  height: 32px;
  width: 32px;

  i {
    position: relative;
    top: 3px;
  }
}

/**
 * Component State: Icon Button
 */


.Icon:focus,
.Icon:active,
.Icon.is-pressed {
  background: rgba(74,140,255,0.10);
  border: 1px solid #4A8CFF;
}

/* Rules for colour of the icon when pressed. */
.Icon:focus i,
.Icon:active i,
.Icon.is-pressed i {
  color: #4A8CFF;
}

/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
