$tab-border-width: 2px;
$wrap-padding-x: 1.5rem;
$spacing-xs: 1rem;

@mixin tab-underline {
  border-bottom: $tab-border-width solid transparent;
}

.TabWrap {
  padding: 0 $wrap-padding-x;
}

.TabNav {
  position: relative;
  border-bottom: $tab-border-width solid #F6F9FB;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    height: $tab-border-width;
    width: $wrap-padding-x;
    background-color: #ffffff;
    bottom: -$tab-border-width;
    left: -$wrap-padding-x;
  }

  &::after {
    content: '';
    position: absolute;
    height: $tab-border-width;
    width: $wrap-padding-x;
    background-color: #ffffff;
    bottom: -$tab-border-width;
    right: -$wrap-padding-x;
  }

  &-list {
    margin-left: -$wrap-padding-x;
    margin-right: -$wrap-padding-x;
    margin-bottom: -$tab-border-width;
    padding: 0 $wrap-padding-x;
    overflow-x: scroll;
  }

  &-item {
    @include tab-underline;
    display: inline-block;
    padding-bottom: $spacing-xs;
    vertical-align: top;

    &:not(:last-child) {
      margin-right: $spacing-xs;
    }

    &:hover {
      @include tab-underline;
      border-color: $color-ain;
      cursor: pointer;
    }

    &[data-state="open"] {
      @include tab-underline;
      border-color: $color-ain;
      color: $color-ain;

    }
  }
}
