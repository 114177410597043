/*------------------------------------*\
    #FLEXBOX GRID
\*------------------------------------*/

/**
 * Flexbox grid
  based on https://codepen.io/harrycresswell/pen/MrYvmZ
  and https://www.taniarascia.com/easiest-flex-grid-ever/
 */

 /*
 Object: Core Flexbox grid
 */


 // Mobie first (grids forms a single column on smaller screens)

.Flex-column {
  flex-basis: 100%;
}

// Flex when screens get larger

@include breakpoint(sm) {
  .Flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .Flex-column {
    flex: 1;
  }

}

/**
 * Non-responsive grids.
 * Add to Flex-row to keep grids even on smaller screens
 * E.g. class="Flex-row Flex--nr"
 */

.Flex-row.Flex--nr{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

/**
 * Object Modifiers: Flexbox styles.
 */

/**
 * Column sizes.
 * Add to Flex-column to modify individual cells
 * E.g. class="Flex-column Flex--75"
 */

 // This needs updating to something less hacky

@include breakpoint(sm) {

  .Flex--75 {
    flex: 7.5;
  }

  .Flex--65 {
    flex: 6.5;
  }

  .Flex--50 {
    flex: 5;
  }

  .Flex--30 {
    flex: 3;
  }

  .Flex--25 {
    flex: 2.5;
  }

  .Flex--15 {
    flex: 1.5;
  }

  .Flex-column--1of2 {
    flex: 0 0 50%;
  }

  .Flex-column--1of4 {
    flex: 0 0 25%;
  }

  .Flex--1of3 {
    flex: 0 0 30%;
  }

   .Flex--1of6 {
    flex: 0 0 16.6666%;
  }

}

/* Justify per row*/
.Flex--right {
  justify-content: flex-end;
}
.Flex--center {
  justify-content: center;
}

/* Alignment per row */
.Flex-row--top {
  align-items: flex-start;
}
.Flex-row--bottom {
  align-items: flex-end;
}
.Flex-row--center {
  align-items: center;
}

/* Alignment per column */
.Flex-column--top {
  align-self: flex-start;
}
.Flex-column--bottom {
  align-self: flex-end;
}
.Flex-column--center {
  align-self: center;
}

/**
 * Add Gutters to columns.
 * Add to Flex-row to give columns gutters
 * E.g. class="Flex-row Flex--gutters"
 */


.Flex--gutters > div {
  margin: 16px;
}


/**
 * Object Modifiers: Demo specific modifer, don not use in production.
 */

.Flex--demo {
  background: rgba(51, 153, 204,.2);
  transition: background-color 0.3s ease;
  border: 1px solid #33cccc;
  padding: 16px;
  margin: 8px;

  &:hover {
   background: rgba(51, 153, 204,.6);
 }
}
