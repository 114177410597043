/*------------------------------------*\
    #TAG
\*------------------------------------*/

/*
Component: Core Tag
*/

.Tag {
  display: inline-block;
  background: $color-white;
  border: solid 1px $color-smoke-10;
  border-radius: $radius-default;
  color: $color-smoke-40;
  margin: .1rem .2rem .1rem 0;
  position: relative;
}

.Tag:hover {
  color: $color-ain;
  border: solid 1px $color-ain;
}

/**
 * Component Modifier: Tag Sizes
 */


.Tag--l {
  font-size: $font-size-default;
  height: 48px;
  line-height: 44px;
  padding: 0 40px 0 16px;

  button {
    position: absolute;
    right: 0;
    top: 13px;
    padding-right: 8px;
  }
}

.Tag--m {
  font-size: $font-size-xs;
  height: 40px;
  line-height: 36px;
  padding: 0 32px 0 16px;

  button {
    position: absolute;
    right: 0;
    top: 11px;
    padding-right: 8px;
  }
}

.Tag--s {
  font-size: $font-size-xs;
  height: 32px;
  line-height: 30px;
  padding: 0 32px 0 16px;

  button {
    position: absolute;
    right: 0;
    top: 8px;
    padding-right: 8px;
  }
}

.Tag--round {
  border-radius: $radius-round;
}
