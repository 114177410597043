/*------------------------------------*\
    #BACKGROUND COLOR
\*------------------------------------*/

/*
  Brand colors
*/

.bg-ain {
  background: $color-ain;
}

.bg-blue-bright, .bg-ain-bright {
  background: $color-ain-bright;
}

.bg-blue-opactiy {
  background: lighten($color-ain, 15%);
}

/*
  Greyscale colors
*/

.bg-white {
  background: $color-white;
}

.bg-dark {
  background: $color-dark;
}

.bg-grey {
  background: $color-snow-10;
}

/*
  UI colors
*/

.bg-green {
  background: $color-positive;
}

.bg-red {
  background: $color-negative;
}

.bg-blue {
  background: $color-ain;
}

.bg-yellow {
  background: $color-warning;
}
