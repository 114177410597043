/*------------------------------------*\
    #ALERT
\*------------------------------------*/

/*
Component: Core Alert
*/

.Alert {
  border-radius: $radius-default;
  font-size: $font-size-xs;
  padding: $spacing-unit-default;
  width: 100%;
}

/*
Component Modifier: Avatar Shapes
*/

.Alert--info {
  color: $color-ain;
  background: lighten($color-ain, 32%);
}

.Alert--warning {
  color: $color-warning;
  background: lighten($color-warning, 36%);
}

.Alert--danger {
  color: $color-negative;
  background: lighten($color-negative, 30%);
}

.Alert--success {
  color: $color-positive;
  background: lighten($color-positive, 42%);
}

/**
 * Component Modifier: Ghost Theme
 */

.Alert--ghost {
  color: $color-white;
  background-color: rgba(255, 255, 255, 0.15);
}

/**
 * Component Modifier: AIN Assistant
 */

 .Alert--assistant  {
   color: $color-smoke-40;
   background: $color-white;
   border: solid 1px $color-snow-30;
 }
