/*------------------------------------*\
    #FONT SIZE
\*------------------------------------*/

.f9 {
 font-size: $font-size-xxxxl; // 48px
}

.f8 {
 font-size: $font-size-xxxl; // 36px
}

.f7 {
 font-size: $font-size-xxl; // 28px
}

.f6 {
 font-size: $font-size-xl; // 24px
}

.f5 {
 font-size: $font-size-l; // 20px
}

.f4 {
  font-size: $font-size-m; // 18px
}

.f3 {
 font-size: $font-size-s; // 16px
}

.f2 {
  font-size: $font-size-xs; //14px
}

.f1 {
  font-size: $font-size-xxs; // 12px
}

.f0 {
  font-size: $font-size-xxxs; // 10px
}
