/**
 * Typography
 */

// @font-face {
//   font-family: AvenirLTStd;
//   font-style: normal;
//   font-weight: 400;
//   src: url('dist/fonts/AvenirLTStd-Book.woff');
// }

@charset "UTF-8";
@font-face {
font-family: 'myriad-pro';
src: url('../images/fonts/MyriadPro-Regular.eot');
src: url('../images/fonts/MyriadPro-Regular.eot') format('embedded-opentype'),
url('../images/fonts/MyriadPro-Regular.woff') format('woff'),
url('../images/fonts/MyriadPro-Regular.ttf') format('truetype'),
url('../images/fonts/MyriadPro-Regular.svg') format('svg');
font-weight: normal;
font-style: normal;
}

/**
 * Font families
 */

$font-family-default: "myriad-pro",sans-serif;
$font-family-serif: serif;
$font-family-mono: MonoWeb, monospace;

/**
 * Font sizes new
 */

$font-size-xxxs: 0.625rem; // 10px
$font-size-xxs: 0.75rem; // 12px
$font-size-xs: 0.875rem; // 14px
$font-size-s: 1rem; // 16px
$font-size-m: 1.125rem; // 18px
$font-size-l: 1.25rem; // 20px
$font-size-xl: 1.5rem; // 24px
$font-size-xxl: 1.75rem; // 28px
$font-size-xxxl: 2.25rem; // 36px
$font-size-xxxxl: 3rem; // 48px

$font-size-default: $font-size-s;

/**
 * Font weights
 */

$font-weight-default: 400;
$font-weight-medium: 600;
$font-weight-bold: 800;

/**
 * Line-heights
 */

$line-height-tight: 1.25rem;
$line-height-default: 1.5rem;
$line-height-loose: 3rem;

// NEW Line height scale
$line-height-xl: 1.6em;
$line-height-l: 1.4em;
//$line-height-m: $base-line-height;
$line-height-s: 1.4em;
$line-height-xs: 1.2em;
