/*------------------------------------*\
    #TEXTAREA
\*------------------------------------*/

/*
Component: Core Form Text Area
*/

textarea {
  width: 100%;
  border-radius: 4px;
  padding: 10px 15px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px $color-smoke-5;
  outline-style: none;
  vertical-align: middle;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0;
  -webkit-transition-duration: 80ms;
  transition-duration: 80ms;
  letter-spacing: 0px;
  line-height: 26px;
}

/**
 * Component Modifier: Default Theme
 */

 //.Textarea--default {}


 .Textarea--default::-webkit-input-placeholder {
  color: #B4C3D3;
 }

 .Textarea--default::-moz-placeholder {  /* Firefox 19+ */
  color: #B4C3D3;
 }

 .Textarea--default:-ms-input-placeholder {
  color: #B4C3D3;
 }


.Textarea--default:hover {
 	box-shadow: inset 0 0 0 1px #c0ccda;
 }

.Textarea--default:focus {
  box-shadow: inset 0 0 0 1px #4a8cff;
 }


 /**
  * Component Modifier: Ghost Theme
  */



 /**
  * Component Modifier: Input Sizes
  */

 .Textarea--l {
 	font-size: $font-size-xs;
 	min-height: 120px;
 }

 .Textarea--m {
 	font-size: $font-size-xxs;
 	min-height: 80px;
 }

 .Textarea--s {
 	font-size: $font-size-xxs;
 	min-height: 60px;
 }
