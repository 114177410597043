/*------------------------------------*\
    #FORM
\*------------------------------------*/

/*
Component: Form
*/

.Form-label {
  display: block;
  line-height: 1.6rem;
  margin-bottom: .3rem;
}
