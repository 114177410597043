/*------------------------------------*\
    #IMAGE
\*------------------------------------*/

/**
 * Enable basic responsive image support
 */

img {
  max-width: 100%;
  vertical-align: middle;
}
