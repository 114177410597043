/*------------------------------------*\
    #LOADING
\*------------------------------------*/

/*
Component: Core Label
*/

.Loading {
height:30px;
width:30px;
margin:0px auto;
position:relative;
-webkit-animation: rotation .6s infinite linear;
-moz-animation: rotation .6s infinite linear;
-o-animation: rotation .6s infinite linear;
animation: rotation .6s infinite linear;
border-left:3px solid rgba(74,140,255,.15);
border-right:3px solid rgba(74,140,255,.15);
border-bottom:3px solid rgba(74,140,255,.15);
border-top:3px solid rgba(74,140,255,.9);
border-radius:100%;
}

@-webkit-keyframes rotation {
from {-webkit-transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);}
}

@-moz-keyframes rotation {
from {-moz-transform: rotate(0deg);}
to {-moz-transform: rotate(359deg);}
}

@-o-keyframes rotation {
from {-o-transform: rotate(0deg);}
to {-o-transform: rotate(359deg);}
}

@keyframes rotation {
from {transform: rotate(0deg);}
to {transform: rotate(359deg);}
}
