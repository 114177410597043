/*------------------------------------*\
    #HEADING
\*------------------------------------*/

h1 {
  font-size: $font-size-xxxxl;
}

h2 {
  font-size: $font-size-xxxl;
}

h3 {
  font-size: $font-size-xxl;
}

h4 {
  font-size: $font-size-xl;
}

h5 {
  font-size: $font-size-l;
}

h6 {
  font-size: $font-size-default;
}
