/*------------------------------------*\
    #COLOR
\*------------------------------------*/

/*
  Brand colors
*/

.color-ain {
  color: $color-ain;
}

.color-blue-bright {
  color: $color-ain-bright;
}

.color-brand {
  color: $color-brand;
}

/*
  Greyscale colors
*/

.color-white {
  color: $color-white;
}

.color-dark {
  color: $color-dark;
}

.color-grey {
  color: $color-snow-10;
}

.color-grey-80 {
  color: $color-smoke-50;
}

.color-grey-60 {
  color: $color-smoke-40;
}

.color-grey-50 {
  color: $color-grey-50;
}

.color-grey-40 {
	color: $color-smoke-20;
}

.color-grey-30 {
  color: $color-smoke-10;
}

/*
  UI colors
*/

.color-green {
  color: $color-positive;
}

.color-red {
  color: $color-negative;
}

.color-blue {
  color: $color-ain;
}

.color-yellow {
  color: $color-warning;
}
