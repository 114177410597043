/*------------------------------------*\
    #CARD
\*------------------------------------*/

/*
Component: Card
*/

/**
 * Component: Card Block
 */

 // This is the core component assigned to every card. Card is portrait by default.

.Card {
  background: $color-white;
  /* Shadow container :normal: */
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 4px 5px 0 rgba(0,0,0,0.04), 0 1px 10px 0 rgba(0,0,0,0.05);
  min-height: 100%;
  position: relative;
  top: 0;
  transition: all .1s ease-in;

  &:hover {
    top: -2px;
    /* Shadow container :elevated: */
    box-shadow: 0 12px 17px 2px rgba(0,0,0,0.04), 0 5px 22px 4px rgba(0,0,0,0.04), 0 7px 8px 0 rgba(0,0,0,0.05);
  }
}

/**
 * Component Modifier: Card Landscape
 */


 // Use modifier after .Card to create a landcape style card layout

.Card--landscape {
  display: grid;
  grid-template-columns: 1fr;
}

@include breakpoint(sm) {
    .Card--landscape {
      grid-template-columns: 1fr 1fr;
    }
}

/**
 * Component: Card-thumb
 */


// use .Card-thumb to set cover image

.Card-thumb {
  background: $color-dark;
  background-size: cover;
  background-position: center center;
  min-height: 184px;
  position: relative;
  height: 100%;
}

.Card-progress {
  position: absolute;
  bottom: 24px;
  width: 85%;
}

/**
 * Component Modifier: Card-thumb portrait & Landscape
 */

// Use modifier after .Card-thumb to create the corect border raduis for portrait style cards

.Card-thumb--portrait {
  border-radius: $radius-default $radius-default 0 0;
}

// Use modifier after .Card-thumb to create the corect border raduis for landscape style cards

.Card-thumb--landscape {
  border-radius: $radius-default $radius-default 0 0;
}

@include breakpoint(sm) {
 .Card-thumb--landscape {
   border-radius: $radius-default 0 0 0;
 }
}

// Use modifier after .Card to create a card with rounded corners

.Card--radius {
 border-radius: $radius-default;
}


// .Card label contains `.Label`, positioning labels in the top right corner of a card.

.Card-label {
  position: absolute;
  right: 24px;
  top: 24px;

  span {
    box-shadow: 0 4px 6px rgba(0,0,0,0.3);
  }
}

// Card location contains location pin icon.

.Card-location {
  color: $color-smoke-40;
  font-size: $font-size-s;
  position: relative;

  i {
    color: $color-brand;
    position: absolute;
    top: 2px;
  }
}

// Use .Card-description to control min-height of description.

.Card-description {
  color: $color-smoke-40;
  font-size: $font-size-s;
  min-height: 72px;
}

// inlines text links

.Card-text-links {
  a {
    display: block;
  }
}

// Use to create a grid within a card, for example when displaying figures.

.Card-grid {
  display: grid;
  grid-gap: 0 16px;
}

// You will need to modify `.Card-grid` with the correct number of grid columns, depending on what the card requires.

.Card-grid--2 {
  grid-template-columns: 1fr 1fr;
}

.Card-grid--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.Card-grid--4 {
  grid-template-columns: 1fr 1fr;
}

.Card-grid--5 {
  grid-template-columns: 1fr 1fr;
}

.Card-grid--6 {
  grid-template-columns: 1fr;
}

@include breakpoint(md) {
  .Card-grid--4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .Card-grid--5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .Card-grid--6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
