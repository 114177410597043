/*------------------------------------*\
    #TOOLTIP
\*------------------------------------*/

/**
 * Component: Core Tooltip
 */

.Tooltip {
	position: relative;
}

 .Tooltip-text {
	border-radius: 4px;
	box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.20);
	color: $color-white;
 	font-size: 13px;
	height: 32px;
	line-height: 32px;
	overflow: hidden;
	/* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
	opacity: 0;
	padding: 0 8px;
	position: absolute;
	text-align: center;
 	text-overflow: ellipsis;
	transition: opacity 0.5s;
	visibility: hidden;
	white-space: nowrap;
	z-index: 1000; /* absurdity 2 */


 }

 .Tooltip:hover .Tooltip-text {
	visibility: visible;
	opacity: 1;

 }

 /**
  * Component Modifier: Tooltip position
  */

 .Tooltip-text--top {
   left: 50%;
   transform: translate(-50%, -.5em);
   bottom: calc(100% + 4px);
 }

 .Tooltip-text--bottom {
   left: 50%;
   transform: translate(-50%, .5em);
   top: calc(100% + 4px);
 }

 .Tooltip-text--left {
   top: 50%;
   right: calc(100% + 4px);
   transform: translate(-.5em, -50%);

 }

 .Tooltip-text--right {
   top: 50%;
   left: calc(100% + 4px);
   transform: translate(.5em, -50%);
 }


 /**
  * Component Modifier: Tooltip theme
  */

 .Tooltip-text--default {
    background: $color-dark;
 }

 .Tooltip-text--success {
    background: $color-positive;
 }

 .Tooltip-text--danger {
    background: $color-negative;;
 }

 .Tooltip-text--info {
    background: $color-ain;
 }
