/*------------------------------------*\
    #INPUT
\*------------------------------------*/

/*
Component: Core Form Input
*/

.Input, input[type="text"],
input[type="password"] {
  appearance: none;
  border-width: 0;
  border-radius: $radius-default;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px $color-smoke-5;
  letter-spacing: 0px;
  line-height: 16px;
  outline-style: none;
  overflow: auto;
  padding: 0 15px;
  transition-duration: 80ms;
  transition-property: all;
  vertical-align: middle;
  width: 100%;

}

input[type="password"] {
  -webkit-text-security: disc;

}

/**
 * Component Modifier: Default Theme
 */

 //.Input--default {}

.Input--default::-webkit-input-placeholder {
  color: #B4C3D3;
}

.Input--default::-moz-placeholder {  /* Firefox 19+ */
  color: #B4C3D3;
}

.Input--default:-ms-input-placeholder {
  color: #B4C3D3;
}

input[type="text"].Input--default:hover,
input[type="password"].Input--default:hover,
input[type="search"].Input--default:hover {
	box-shadow: inset 0 0 0 1px #c0ccda;
}

input[type="text"].Input--default:focus,
input[type="password"].Input--default:focus,
input[type="search"].Input--default:focus {
  box-shadow: inset 0 0 0 1px #4a8cff;
}

/**
 * Component Modifier: Input Sizes
 */

.Input--l {
	font-size: $font-size-xs;
	height: 48px;
  line-height: 48px;
}

.Input--m {
	font-size: $font-size-xxs;
	height: 40px;
  line-height: 40px;
}

.Input--s {
	font-size: $font-size-xxs;
	height: 32px;
  line-height: 32px;
}

/**
 * Component Modifier: Ghost Theme
 */



 /**
  * Component Modifier: Input hints
  */

.Input.has-danger {
   border: solid 1px $color-negative;
}

.Input.has-success {
  border: solid 1px $color-positive;
}

.Input-hint--danger {
  color: $color-negative;
}

.Input-hint--success {
  color: $color-positive;
}
