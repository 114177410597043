/*------------------------------------*\
    #LABEL
\*------------------------------------*/

/*
Component: Core Label
*/

.Label {
  border-radius: 4px;
  color: $color-white;
  font-size: $font-size-xxs;
  height: 24px;
  line-height: 24px;
  padding: 0 16px;
}

.Label-icon {
  border-radius: 4px;
  color: $color-white;
  font-size: $font-size-xxs;
  height: 24px;
  line-height: 24px;
  position: relative;
}

/*
Component Modifier: Label Shapes
*/

.Label--round {
  border-radius: $radius-round;
}

/*
Component Modifier: Label Colors
*/


.Label--default {
  color: $color-smoke-40;
  background: $color-snow-30;
}

.Label--info, .Label--brand {
  background: $color-ain;
}

.Label--warning {
  background: $color-warning;
}

.Label--danger {
  background: $color-negative;
}

.Label--success {
  background: $color-positive;
}

.Label--white {
  color: $color-smoke-40;
  background: $color-white;
  border: solid 1px $color-smoke-10;
}

.Label--dark {
  background: $color-dark;
}

.Label--seedtribe {
  background: rgba(109,18,255,1);
  background: -moz-linear-gradient(left, rgba(109,18,255,1) 0%, rgba(109,18,255,1) 38%, rgba(0,93,255,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(109,18,255,1)), color-stop(38%, rgba(109,18,255,1)), color-stop(100%, rgba(0,93,255,1)));
  background: -webkit-linear-gradient(left, rgba(109,18,255,1) 0%, rgba(109,18,255,1) 38%, rgba(0,93,255,1) 100%);
  background: -o-linear-gradient(left, rgba(109,18,255,1) 0%, rgba(109,18,255,1) 38%, rgba(0,93,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(109,18,255,1) 0%, rgba(109,18,255,1) 38%, rgba(0,93,255,1) 100%);
  background: linear-gradient(to right, rgba(109,18,255,1) 0%, rgba(109,18,255,1) 38%, rgba(0,93,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6d12ff', endColorstr='#005dff', GradientType=1 );
}

/**
 * Component Modifier: Ghost Theme
 */

.Alert--ghost {
  color: $color-white;
  background-color: rgba(255, 255, 255, 0.15);
}

/**
 * Component Modifier: Label icon position. Required by all labels with an icon.
 */


.Label-icon--right {
  padding: 0 32px 0 16px;

  i {
    position: absolute;
    right: 8px;
    top: 2px;
  }
}

.Label-icon--left {
  padding: 0 16px 0 32px;

  i {
    position: absolute;
    left: 8px;
    top: 2px;
  }
}

.Label-icon--brand {
  height: 32px;
  line-height: 24px;
  padding: 4px 16px 4px 32px;

  i {
    position: absolute;
    left: 8px;
    top: 6px;
  }
}
