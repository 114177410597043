/*------------------------------------*\
    #SEARCH
\*------------------------------------*/

/*
Component: Core Form Input
*/

.Search {
  position: relative;
}

/**
* Component Modifier: Search Icon Sizes
*/

.Search__icon--l {
  position: absolute;
  top: 0;
  z-index: 1;
  line-height: 48px;
  padding-left: 16px;

  i {
    vertical-align: middle;
  }
}

.Search__icon--m {
  position: absolute;
  top: 0;
  line-height: 40px;
  padding-left: 16px;
  z-index: 1;

  i {
    vertical-align: middle;
  }
}


.Search__input {
  width: 100%;
  border-radius: $radius-default;
  padding: 12px 45px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px $color-smoke-5;
  outline-style: none;
  vertical-align: middle;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0;
  -webkit-transition-duration: 80ms;
  transition-duration: 80ms
}
