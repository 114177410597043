/*------------------------------------*\
    #ICON
\*------------------------------------*/

/*
Component: Core Icon
*/

//.Icon {}

/**
 * Component Modifier: Brand icons
 */

.Icon--seedtribe {
  background-image: url("/assets/toolkit/images/ic_seedtribe.svg");
  background-size: 20px 20px;
}

/**
 * Component Modifier: Icon Size
 */

 .Icon--xs {
   line-height: 28px;
   height: 24px;
   width: 24px;

   i {
     position: relative;
     top: 0px;
   }
 }
