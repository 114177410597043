/*------------------------------------*\
    #MARKER
\*------------------------------------*/

/*
Component: Core Marker
*/

.Marker {
  border-radius: $radius-round;
  color: $color-white;
  height: 8px;
  margin-right: 8px;
  width: 8px;
}

/*
Component Modifier: Marker Colors
*/


.Marker--default {
  background: $color-snow-30;
}

.Marker--info, .Marker--brand {
  background: $color-ain;
}

.Marker--warning {
  background: $color-warning;
}

.Marker--danger {
  background: $color-negative;
}

.Marker--success {
  background: $color-positive;
}

.Marker--white {
  background: $color-white;
  border: solid 1px $color-smoke-10;
}

.Marker--dark {
  background: $color-dark;
}

/**
 * Component Modifier: Ghost Theme
 */

.Marker--ghost {
  color: $color-white;
  background-color: rgba(255, 255, 255, 0.15);
}
