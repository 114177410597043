/*------------------------------------*\
    #SHARED
\*------------------------------------*/

button:focus, textarea {
  outline: none;
}

span {
  display: inline-block;
}


code {
  color: $color-ain;
}
