/*------------------------------------*\
    #SELECT
\*------------------------------------*/

/*
Component: Core Form Input
*/

select {
  text-transform: none;
  margin: 0;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-style: none;
}

.Select {
  width: 100%;
  border-radius: 4px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px $color-smoke-5;
  outline-style: none;
  vertical-align: middle;
  border-width: 0;
  -webkit-transition-duration: 80ms;
  transition-duration: 80ms;
}

.Select:after {
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    -webkit-transition: none;
    transition: none;
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: $zIndex-1;
}

/**
 * Component Modifier: Default Theme
 */

 //.Select--default {}

.Select--default:hover {
 	box-shadow: inset 0 0 0 1px #c0ccda;
 }

 /**
  * Component Modifier: Ghost Theme
  */



 /**
  * Component Modifier: Input Sizes
  */

 .Select--l select {
 	font-size: 16px;
 	height: 48px;
  line-height: 48px;
  padding: 0 50px 0 20px;
  position: relative;
  z-index: $zIndex-2;
 }

 .Select--m select {
 	font-size: 14px;
 	height: 40px;
  line-height: 40px;
  padding: 0 50px 0 20px;
  position: relative;
  z-index: $zIndex-2;
 }

 .Select--s select {
 	font-size: 12px;
 	height: 32px;
  line-height: 32px;
  padding: 0 50px 0 20px;
  position: relative;
  z-index: $zIndex-2;
 }

.Select--l:after {
  width: 24px;
  height: 24px;
  margin-top: 14px;
  margin-right: 14px;
  background-size: 24px 24px;
  background-image: url("/assets/toolkit/images/icon-dropdown--m.svg");
}

.Select--m:after {
  width: 24px;
  height: 24px;
  margin-top: 8px;
  margin-right: 10px;
  background-size: 24px 24px;
  background-image: url("/assets/toolkit/images/icon-dropdown--s.svg");
}

.Select--s:after {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-right: 6px;
  background-size: 24px 24px;
  background-image: url("/assets/toolkit/images/icon-dropdown--s.svg");
}
