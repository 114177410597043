/*------------------------------------*\
    #MARGIN
\*------------------------------------*/

// margin all

.ma8 {
  margin: 80px;
}

.ma7 {
  margin: 64px;
}

.ma6 {
  margin: 48px;
}

.ma5 {
  margin: 32px;
}

.ma4 {
  margin: 24px;
}

.ma3 {
  margin: 16px;
}

.ma2 {
  margin: 8px;
}

.ma1 {
  margin: 4px;
}

.ma0 {
  margin: 0;
}


// margin top

.mt8 {
  margin-top: 80px;
}

.mt7 {
  margin-top: 64px;
}

.mt6 {
  margin-top: 48px;
}

.mt5 {
  margin-top: 32px;
}

.mt4 {
  margin-top: 24px;
}

.mt3 {
  margin-top: 16px;
}

.mt2 {
  margin-top: 8px;
}

.mt1 {
  margin-top: 4px;
}

.mt0 {
  margin-top: 0;
}

// margin left

.ml8 {
  margin-left: 80px;
}

.ml7 {
  margin-left: 64px;
}

.ml6 {
  margin-left: 48px;
}

.ml5 {
  margin-left: 32px;
}

.ml4 {
  margin-left: 24px;
}

.ml3 {
  margin-left: 16px;
}

.ml2 {
  margin-left: 8px;
}

.ml1 {
  margin-left: 4px;
}

.ml0 {
  margin-left: 0;
}

// margin right

.mr8 {
  margin-right: 80px;
}

.mr7 {
  margin-right: 64px;
}

.mr6 {
  margin-right: 48px;
}

.mr5 {
  margin-right: 32px;
}

.mr4 {
  margin-right: 24px;
}

.mr3 {
  margin-right: 16px;
}

.mr2 {
  margin-right: 8px;
}

.mr1 {
  margin-right: 4px;
}

.mr0 {
  margin-right: 0;
}

// margin bottom

.mb8 {
  margin-bottom: 80px;
}

.mb7 {
  margin-bottom: 64px;
}

.mb6 {
  margin-bottom: 48px;
}

.mb5 {
  margin-bottom: 32px;
}

.mb4 {
  margin-bottom: 24px;
}

.mb3 {
  margin-bottom: 16px;
}

.mb2 {
  margin-bottom: 8px;
}

.mb1 {
  margin-bottom: 4px;
}

.mb0 {
  margin-bottom: 0;
}
