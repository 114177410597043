/*------------------------------------*\
    #BASE
\*------------------------------------*/

/**
 * Base styles
 */

/**
 * Enable font anti-aliasing in
 * 1. Firefox
 * 2. Chrome
 */



html {
  height: 100%;
  -moz-osx-font-smoothing: grayscale; /* 1 */
  -webkit-font-smoothing: antialiased; /* 2 */
}

body {
  color: $color-dark;
  font-family: $font-family-default;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  min-height: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

/**
 * Ensure all 'a' elements have correct cursor style
 */

a {
  color: $color-ain;
  cursor: pointer;
}

/**
 * Reset font-weight of header elements
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-default;
}

/**
 * Reset the margin of these elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
}




/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

/* stylelint-disable selector-no-qualifying-type */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  cursor: pointer; /* 2 */
}

button {
  border: 0;
  padding: 0;
}
